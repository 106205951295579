import $ from 'jquery'; /*eslint-disable*/
import moment from 'moment'; /*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import '../../css/pavFiltros/filtros.css';

import { elipseVerde, setaDropdown } from '../../utils/alternanciaIcones';
import Calendario from '../calendario/calendario';
// import FiltroArquivo from './filtroArquivo';
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js';
import { api } from '../../conexoes/api';
import {
  filtrosArrayBarra,
  maximoTagsExibidas,
  recarregarBarraFiltragem,
  renderizaOperadora,
  revelarBarraFiltragem
} from '../../redux/actions';
import { limparFiltr } from '../../utils/alternanciaIcones';
import decryptJWT from '../../utils/decryptJWT';
import { isPortal } from '../../utils/nivelCliente';
import SelectGrupo from '../componentesModais/selects/selectAlterarGrupos';
import SelectGenerico from '../componentesModais/selects/selectGenerico';
import SelectMarca from '../componentesModais/selects/selectMarca';
import SelectModelo from '../componentesModais/selects/selectModelo';
import SelectStatusContrato from '../componentesModais/selects/selectStatusContrato';

let filtroOperadoratt = []

function Filtros(props) {
  const clientePortal = isPortal()

  const despacho = useDispatch()
  // const [renderizarFiltroArquivo, setRenderizarFiltroArquivo] = useState(false)
  let recarregarBarraFiltragemValor = useSelector(
    (state) => state.filtrosState.recarregarBarraFiltragem
  )
  let filtros = useSelector((state) => state.filtrosState.filtros)
  let arrayFiltrosSimcard = []
  const codigoClienteErp = decryptJWT('clientePrimario')

  // SPINNER
  const [spinAtivo, setSpinAtivo] = useState(false)

  function hendlerGetdadosFiltros() {
    if (!filtros.vazio) {
      //seta os valores dos inputs
      if (filtros.OPERADORA != undefined && filtros.OPERADORA != '') {
        setFiltroOperadora(filtros.OPERADORA.split(','))
        filtroOperadoratt = filtros.OPERADORA.split(',')
      }
      if (filtros.VALOR_PLANO != undefined && filtros.VALOR_PLANO != '') {
        setFiltroValorPlano(filtros.VALOR_PLANO.split(','))
        filtroValorPlanott = filtros.VALOR_PLANO.split(',')
      }
      setFiltroTecnologia(filtros.TECNOLOGIA)
      setFiltroTipoSimcard(filtros.TIPO_SIMCARD)
      setFiltroPlanoTipo(filtros.PLANO_TIPO)
      setFiltroPacoteExtra(filtros.PACOTE_EXTRA)
      setFiltroSituacaoContrato(filtros.CD_SITUACAO)
      if (filtros.SMS_ATIVADO == '1') setFiltroEnvioSms('Liberado')
      if (filtros.SMS_ATIVADO == '0') setFiltroEnvioSms('Bloqueado')
      setFiltroUltimaConexao(filtros.ULTIMA_CONEXAO)
      setFiltroConsumo(filtros.CONSUMO)
      if (filtros.CONSUMO) {
        setPermitirConsumo(true)
      }
      setFiltroTrafego(filtros.TRAFEGO)
      setFiltroConexoes(filtros.CONEXOES)
      if (filtros.CONEXOES) {
        setPermitirConexoes(true)
      }
      setFiltroPacotes(filtros.PACOTES)
      if (filtros.PACOTES) {
        setPermitirPacotes(true)
      }
      setFiltroGrupo(filtros.GRUPO)
      if (!filtros.GRUPO) {
        setFiltroGrupo('Selecione')
      }
      if (!filtros.STATUS_CONTRATO) {
        setNomeContrato('Selecione')
      }
      setNomeContrato(filtros.STATUS_CONTRATO)
      setFiltroPlanoMinimo(filtros.PLANO_MIN)
      setFiltroPlanoMaximo(filtros.PLANO_MAX)
      setFiltroConsumoMinimo(filtros.CONSUMO_MIN)
      setFiltroConsumoMaximo(filtros.CONSUMO_MAX)
      setFiltroConexoesMinimo(filtros.CONEXOES_MIN)
      setFiltroConexoesMaximo(filtros.CONEXOES_MAX)
      setFiltroPacotesMinimo(filtros.PACOTES_MIN)
      setFiltroPacotesMaximo(filtros.PACOTES_MAX)
      setFiltroSaldoMinimo(filtros.SALDO_MIN)
      setFiltroSaldoMaximo(filtros.SALDO_MAX)
      setFiltroIccidMinimo(filtros.RANGE_ICCID_MIN)
      setFiltroIccidMaximo(filtros.RANGE_ICCID_MAX)
      setFiltroValorMinimo(filtros.VALOR_MIN)
      setFiltroValorMaximo(filtros.VALOR_MAX)
      setFiltroDiasUtilizadosMinimo(filtros.DIAS_USADOS_MIN)
      setFiltroDiasUtilizadosMaximo(filtros.DIAS_USADOS_MAX)
      if (!filtros.MARCA) {
        setFiltroMarca('Selecione')
      } else {
        setFiltroMarca(filtros.MARCA)
      }
      if (!filtros.MODELO) {
        setFiltroModelo('Selecione')
      } else {
        setFiltroModelo(filtros.MODELO)
      }

      if (!filtros.SERVICO_ADICIONAL) {
        setDsServicoAdicional('Selecione')
      } else {
        setDsServicoAdicional(filtros.SERVICO_ADICIONAL)
      }

      setFiltroClienteFinal(filtros.CLIENTE_FINAL_CODIGO)
      setFiltroNomeClienteFinal(filtros.CLIENTE_FINAL_NOME)

      //verifica se existe algo nos valores de datas
      if (filtros.AQUISICAO_INICIO != '' && filtros.AQUISICAO_INICIO) {
        let auxData = new Date(filtros.AQUISICAO_INICIO)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroAquisicaoDataInicio(auxData)
      }
      if (filtros.AQUISICAO_FINAL != '' && filtros.AQUISICAO_FINAL) {
        let auxData = new Date(filtros.AQUISICAO_FINAL)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroAquisicaoDataFinal(auxData)
      }
      if (
        filtros.ULTIMA_CONEXAO_INICIO != '' &&
        filtros.ULTIMA_CONEXAO_INICIO
      ) {
        setFiltroUltimaConexao('periodo')
        let auxData = new Date(filtros.ULTIMA_CONEXAO_INICIO)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroUltimaConexaoDataInicio(auxData)
      }
      if (filtros.ULTIMA_CONEXAO_FINAL != '' && filtros.ULTIMA_CONEXAO_FINAL) {
        setFiltroUltimaConexao('periodo')
        let auxData = new Date(filtros.ULTIMA_CONEXAO_FINAL)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroUltimaConexaoDataFinal(auxData)
      }
      if (filtros.ATIVACAO_INICIO != '' && filtros.ATIVACAO_INICIO) {
        let auxData = new Date(filtros.ATIVACAO_INICIO)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroAtivacaoDataInicio(auxData)
      }
      if (filtros.ATIVACAO_FINAL != '' && filtros.ATIVACAO_FINAL) {
        let auxData = new Date(filtros.ATIVACAO_FINAL)
        // auxData.setDate(auxData.getDate()+1)
        setFiltroAtivacaoDataFinal(auxData)
      }
    }
  }
  useEffect(() => {
    hendlerGetdadosFiltros()
  }, [])

  useEffect(() => {
    if (filtros.vazio) {
      setFiltroOperadora([])
    }
  }, [])

  //VERIFICANDO 1
  // useEffect(() => {}, [recarregarBarraFiltragemValor])

  //verifica se os radios butons estao ativos
  function handlerCheck(element, nome) {
    // filtros.map((item))
    if (filtros[element] == nome) {
      return true
    } else {
      return false
    }
  }

  async function handlerCheckBox(nome, codigo) {
    let validacao
    if (filtros.vazio != 'vazio') {
      if (filtros[nome]) {
        await Promise.all(
          filtros[nome].split(',').map((item) => {
            if (item == codigo) {
              validacao = true
            }
          })
        )
      }
    }
    if (validacao === true) {
      return true
    } else {
      return false
    }
  }

  async function handleFuncaoEstadosRedux() {
    if (filtroOperadora.length > 0 && filtroOperadora) {
      let operadorasTemp = ''
      await Promise.all(
        dadoSelectOperadora.map((item) => {
          filtroOperadora.map((item_filtro) => {
            if (item.cd_dsp_operadora == item_filtro) {
              operadorasTemp +=
                ', ' +
                item.ds_dsp_operadora?.trim() +
                ' ' +
                item.cd_dsp_operadora
            }
          })
        })
      )
      operadorasTemp = operadorasTemp.replace(',', '')
      let quebrado = operadorasTemp.split(',')
      for (let i = 0; i < quebrado.length; i++)
        arrayFiltrosSimcard.push(quebrado[i])
      despacho(renderizaOperadora(dadoSelectOperadora))
    }

    if (filtroValorPlano.length > 0 && filtroValorPlano) {
      let ValorPlanoTemp = ''
      await Promise.all(
        dadoCheckValorPlano.map((item) => {
          filtroValorPlano.map((item_filtro) => {
            if (item.n2_valor == item_filtro) {
              ValorPlanoTemp += ', ' + item.n2_valor
            }
          })
        })
      )
      ValorPlanoTemp = ValorPlanoTemp.replace(',', '')
      arrayFiltrosSimcard.push(
        '' + converterNumeroBarraPesquisa(ValorPlanoTemp + ',') + '?VALOR_PLANO'
      )
    }

    if (
      dsServicoAdicional !== '' &&
      dsServicoAdicional !== 'Selecione' &&
      dsServicoAdicional
    ) {
      arrayFiltrosSimcard.push(
        'Serviço Adicional: ' + dsServicoAdicional + '?SERVICO_ADICIONAL'
      )
    }
    if (filtroMarca !== '' && filtroMarca !== 'Selecione' && filtroMarca) {
      arrayFiltrosSimcard.push('' + filtroMarca + '?MARCA')
    }
    if (filtroModelo !== '' && filtroModelo !== 'Selecione' && filtroModelo) {
      arrayFiltrosSimcard.push('' + filtroModelo + '?MODELO')
    }
    if (filtroTecnologia !== '' && filtroTecnologia) {
      if (filtroTecnologia === 'NB') {
        arrayFiltrosSimcard.push('NB-loT' + '?ULTIMA_CONEXAO')
      } else {
        arrayFiltrosSimcard.push('' + filtroTecnologia + '?TECNOLOGIA')
      }
    }
    if (filtroTipoSimcard !== '' && filtroTipoSimcard) {
      arrayFiltrosSimcard.push('' + filtroTipoSimcard + '?TIPO_SIMCARD')
    }
    if (filtroPlanoTipo !== '' && filtroPlanoTipo) {
      arrayFiltrosSimcard.push('' + filtroPlanoTipo + '?PLANO_TIPO')
    }
    if (filtroPacoteExtra !== '' && filtroPacoteExtra) {
      arrayFiltrosSimcard.push(filtroPacoteExtra + ' pacote extra?PACOTE_EXTRA')
    }
    if (filtroEnvioSms !== '' && filtroEnvioSms) {
      arrayFiltrosSimcard.push('' + filtroEnvioSms + '?SMS_ATIVADO')
    }
    if (
      filtroPlanoMinimo !== '' &&
      filtroPlanoMaximo !== '' &&
      filtroPlanoMinimo &&
      filtroPlanoMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Plano: entre ' +
          filtroPlanoMinimo +
          ' MB e ' +
          filtroPlanoMaximo +
          ' MB' +
          '?PLANO_MIN'
      )
    } else if (filtroPlanoMinimo !== '' && filtroPlanoMinimo) {
      arrayFiltrosSimcard.push(
        'Plano: a partir de ' + filtroPlanoMinimo + 'MB' + '?PLANO_MIN'
      )
    } else if (filtroPlanoMinimo !== '' && filtroPlanoMinimo) {
      arrayFiltrosSimcard.push(
        'Plano: até ' + filtroPlanoMaximo + 'MB' + '?PLANO_MAX'
      )
    }
    if (
      nomeContrato !== '' &&
      nomeContrato &&
      nomeContrato != 'Selecione' &&
      !resetContrato
    ) {
      arrayFiltrosSimcard.push('' + nomeContrato.split('*')[0] + '?CD_SITUACAO')
    }
    if (
      filtroGrupo !== '' &&
      filtroGrupo &&
      filtroGrupo != 'Selecione' &&
      !resetGrupo
    ) {
      arrayFiltrosSimcard.push('' + filtroGrupo.split('*')[0] + '?GRUPO')
    }
    if (
      filtroUltimaConexaoDataInicio !== '' &&
      filtroUltimaConexaoDataFinal !== '' &&
      filtroUltimaConexaoDataInicio &&
      filtroUltimaConexaoDataFinal
    ) {
      arrayFiltrosSimcard.push(
        'Último evento: Período de ' +
          moment(filtroUltimaConexaoDataInicio).format('DD-MM-YYYY') +
          ' até ' +
          moment(filtroUltimaConexaoDataFinal).format('DD-MM-YYYY') +
          '?ULTIMA_CONEXAO_INICIO'
      )
    } else if (
      filtroUltimaConexaoDataInicio !== '' &&
      filtroUltimaConexaoDataInicio
    ) {
      arrayFiltrosSimcard.push(
        `Último evento: Período a partir de ${moment(filtroUltimaConexaoDataInicio).format('DD-MM-YYYY')}` +
          '?ULTIMA_CONEXAO_INICIO'
      )
    } else if (
      filtroUltimaConexaoDataFinal !== '' &&
      filtroUltimaConexaoDataFinal
    ) {
      arrayFiltrosSimcard.push(
        `Último evento: Período até ${moment(filtroUltimaConexaoDataFinal).format('DD-MM-YYYY')}` +
          '?ULTIMA_CONEXAO_INICIO'
      )
    }
    if (filtroUltimaConexao !== '' && filtroUltimaConexao) {
      if (filtroUltimaConexao === 'ultima_conexao') {
        arrayFiltrosSimcard.push('Offline' + '?ULTIMA_CONEXAO')
      }
      if (filtroUltimaConexao === 'online') {
        arrayFiltrosSimcard.push('Online' + '?ULTIMA_CONEXAO')
      }
      if (filtroUltimaConexao === 'estoque') {
        arrayFiltrosSimcard.push('Estoque' + '?ULTIMA_CONEXAO')
      }
    }
    if (
      filtroAquisicaoDataInicio !== '' &&
      filtroAquisicaoDataFinal !== '' &&
      filtroAquisicaoDataInicio &&
      filtroAquisicaoDataFinal
    ) {
      arrayFiltrosSimcard.push(
        'Aquisição: entre ' +
          moment(filtroAquisicaoDataInicio).format('DD-MM-YYYY') +
          ' e ' +
          moment(filtroAquisicaoDataFinal).format('DD-MM-YYYY') +
          '?AQUISICAO_INICIO'
      )
    }
    if (
      filtroAtivacaoDataInicio !== '' &&
      filtroAtivacaoDataFinal !== '' &&
      filtroAtivacaoDataFinal &&
      filtroAtivacaoDataInicio
    ) {
      arrayFiltrosSimcard.push(
        'Ativação: entre ' +
          moment(filtroAtivacaoDataInicio).format('DD-MM-YYYY') +
          ' e ' +
          moment(filtroAtivacaoDataFinal).format('DD-MM-YYYY') +
          '?ATIVACAO_INICIO'
      )
    }
    if (
      filtroConsumo !== '' &&
      filtroConsumoMinimo !== '' &&
      filtroConsumoMaximo !== '' &&
      filtroConsumo === 'ciclo' &&
      filtroConsumoMinimo &&
      filtroConsumoMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Consumo no ' +
          filtroConsumo +
          ': entre ' +
          filtroConsumoMinimo +
          '% e ' +
          filtroConsumoMaximo +
          '%' +
          '?CONSUMO_MIN'
      )
    }
    if (
      filtroConsumo !== '' &&
      filtroConsumoMinimo !== '' &&
      filtroConsumoMaximo !== '' &&
      filtroConsumo === 'dia' &&
      filtroConsumoMinimo &&
      filtroConsumoMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Consumo no ' +
          filtroConsumo +
          ': entre ' +
          filtroConsumoMinimo +
          ' MB e ' +
          filtroConsumoMaximo +
          ' MB' +
          '?CONSUMO_MIN'
      )
    }
    if (filtroTrafego !== '' && filtroTrafego) {
      arrayFiltrosSimcard.push('' + filtroTrafego + '?TRAFEGO')
    }
    if (
      filtroConexoes !== '' &&
      filtroConexoesMinimo !== '' &&
      filtroConexoesMaximo !== '' &&
      filtroConexoes &&
      filtroConexoesMinimo &&
      filtroConexoesMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Conexões no ' +
          filtroConexoes +
          ': entre ' +
          filtroConexoesMinimo +
          ' e ' +
          filtroConexoesMaximo +
          '?CONEXOES_MIN'
      )
    }
    if (
      filtroPacotes !== '' &&
      filtroPacotesMinimo !== '' &&
      filtroPacotesMaximo !== '' &&
      filtroPacotes &&
      filtroPacotesMinimo &&
      filtroPacotesMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Pacotes no ' +
          filtroPacotes +
          ': entre ' +
          filtroPacotesMinimo +
          ' e ' +
          filtroPacotesMaximo +
          '?PACOTES_MIN'
      )
    }
    if (
      filtroSaldoMinimo !== '' &&
      filtroSaldoMaximo !== '' &&
      filtroSaldoMinimo &&
      filtroSaldoMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Saldo: entre ' +
          filtroSaldoMinimo +
          ' MB e ' +
          filtroSaldoMaximo +
          ' MB' +
          '?SALDO_MIN'
      )
    }
    if (
      filtroValorMinimo !== '' &&
      filtroValorMaximo !== '' &&
      filtroValorMinimo &&
      filtroValorMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Valor: entre R$ ' +
          filtroValorMinimo +
          ' e R$ ' +
          filtroValorMaximo +
          '?VALOR_MIN'
      )
    } else if (
      filtroValorMinimo !== '' &&
      filtroValorMaximo == '' &&
      filtroValorMinimo
    ) {
      // CASO O USUARIO INFORMAR APENAS O VALOR MÍNIMO
      arrayFiltrosSimcard.push(
        'Valor: a partir de R$ ' + filtroValorMinimo + '?VALOR_MIN'
      )
    } else if (
      filtroValorMinimo == '' &&
      filtroValorMaximo !== '' &&
      filtroValorMaximo
    ) {
      // CASO O USUARIO INFORMAR APENAS O VALOR MÁXIMO
      arrayFiltrosSimcard.push(
        'Valor: até R$ ' + filtroValorMaximo + '?VALOR_MAX'
      )
    }
    if (
      filtroDiasUtilizadosMinimo !== '' &&
      filtroDiasUtilizadosMaximo !== '' &&
      filtroDiasUtilizadosMinimo &&
      filtroDiasUtilizadosMaximo
    ) {
      arrayFiltrosSimcard.push(
        'Dias utilizados: entre ' +
          filtroDiasUtilizadosMinimo +
          ' e ' +
          filtroDiasUtilizadosMaximo +
          ' dias' +
          '?DIAS_USADOS_MIN'
      )
    }
    if (
      filtroIccidMinimo !== '' &&
      filtroIccidMaximo !== '' &&
      filtroIccidMinimo !== undefined &&
      filtroIccidMaximo !== undefined
    ) {
      arrayFiltrosSimcard.push(
        'ICCID entre ' +
          filtroIccidMinimo +
          ' e ' +
          filtroIccidMaximo +
          '?RANGE_ICCID_MIN'
      )
    } else if (filtroIccidMinimo !== '' && filtroIccidMinimo !== undefined) {
      arrayFiltrosSimcard.push(
        'ICCID a partir de ' + filtroIccidMinimo + '?RANGE_ICCID_MIN'
      )
    } else if (filtroIccidMaximo !== '' && filtroIccidMaximo !== undefined) {
      arrayFiltrosSimcard.push(
        'ICCID menor que ' + filtroIccidMaximo + '?RANGE_ICCID_MIN'
      )
    }
    if (!clientePortal) {
      if (filtroClienteFinal !== '' && filtroClienteFinal !== undefined) {
        arrayFiltrosSimcard.push(
          '' + filtroClienteFinal + '?CLIENTE_FINAL_CODIGO'
        )
      }
      if (
        filtroNomeClienteFinal !== '' &&
        filtroNomeClienteFinal !== undefined
      ) {
        arrayFiltrosSimcard.push(
          '' + filtroNomeClienteFinal + '?CLIENTE_FINAL_NOME'
        )
      }
    }

    // arrayFiltrosTagsRestante
    despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
    despacho(filtrosArrayBarra(arrayFiltrosSimcard))
    despacho(revelarBarraFiltragem(true))
    despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
  }

  const [dadoSelectGrupo, setDadoSelectGrupo] = useState([])
  const [dadoSelectOperadora, setDadoSelectOperadora] = useState([])
  const [dadoCheckValorPlano, setDadoCheckValorPlano] = useState([])

  useEffect(() => {
    async function carregarGrupos() {
      let todosGrupos = []

      //carrega os 3 grupos padroes
      const gruposPadroes = await api.get(
        '/m1/consultar/simcard_grupo?fl_padrao=1'
      )
      todosGrupos = gruposPadroes.data.dados

      // carrega o grupos do cliente
      const { data } = await api.get(
        '/m1/consultar/simcard_grupo?fl_padrao=0&cd_pav_cliente=' +
          parseInt(decryptJWT('codigoCliente'))
      )
      await data.dados.map((item) => {
        todosGrupos.push(item)
      })
      setDadoSelectGrupo(todosGrupos)
    }
    carregarGrupos()

    // async function carregarOperadoras() {
    //     let todasOperadoras = [];
    //     //carrega os 3 grupos padroes
    //     const operadorasPadroes = await api.get('/m1/consultar/operadora/operadora_cliente_simcard?cd_pav_cliente=' + parseInt(decryptJWT('codigoCliente')))
    //     todasOperadoras = operadorasPadroes.data.dados;
    //     setDadoSelectOperadora(todasOperadoras)
    // }
    // carregarOperadoras();

    // async function carregarValores(){

    //     let todosValores = [];
    //     const valoresPlano = await api.get('/m1/consultar/simcard/valor_plano_simcard?codigo_cliente='+decryptJWT("codigoCliente"))
    //     todosValores = valoresPlano.data.dados
    //     setDadoCheckValorPlano(todosValores)

    // }
    // carregarValores();

    // async function carregarTiposSimcard() {
    //     const { data } = await api.get('/m1/consultar/simcard_tipo');
    //     if (data.status == 200) {
    //         setTiposSimcard(data.dados)
    //     }
    // }
    // carregarTiposSimcard()
  }, [])

  // const cdCliente = decryptJWT('codigoCliente')

  //HOOKS DE DATAS BASICAS
  const [dataMin, setDataMin] = useState(1990)
  const [dataMax, setDataMax] = useState(new Date())
  //HOOKS DOS VALORES DOS FILTROS
  // let arrayOperadora = []
  const [filtroOperadora, setFiltroOperadora] = useState([])
  const [filtroValorPlano, setFiltroValorPlano] = useState([])
  const [filtroTecnologia, setFiltroTecnologia] = useState('')
  const [filtroTipoSimcard, setFiltroTipoSimcard] = useState('')
  const [filtroPlanoTipo, setFiltroPlanoTipo] = useState('')
  const [filtroGrupo, setFiltroGrupo] = useState('Selecione')
  const [cdGrupo, setCdGrupo] = useState('')
  const [filtroPacoteExtra, setFiltroPacoteExtra] = useState('')
  const [filtroEnvioSms, setFiltroEnvioSms] = useState('')
  const [filtroPlanoMinimo, setFiltroPlanoMinimo] = useState('')
  const [filtroPlanoMaximo, setFiltroPlanoMaximo] = useState('')
  const [filtroUltimaConexao, setFiltroUltimaConexao] = useState('')
  const [filtroUltimaConexaoDataInicio, setFiltroUltimaConexaoDataInicio] =
    useState('')
  const [filtroUltimaConexaoDataFinal, setFiltroUltimaConexaoDataFinal] =
    useState('')
  const [filtroAquisicaoDataInicio, setFiltroAquisicaoDataInicio] = useState('')
  const [filtroAquisicaoDataFinal, setFiltroAquisicaoDataFinal] = useState('')
  const [filtroAtivacaoDataInicio, setFiltroAtivacaoDataInicio] = useState('')
  const [filtroAtivacaoDataFinal, setFiltroAtivacaoDataFinal] = useState('')
  const [filtroConsumo, setFiltroConsumo] = useState('')
  const [filtroConsumoMinimo, setFiltroConsumoMinimo] = useState('')
  const [filtroConsumoMaximo, setFiltroConsumoMaximo] = useState('')
  const [filtroTrafego, setFiltroTrafego] = useState('')
  const [filtroConexoes, setFiltroConexoes] = useState('')
  const [permitirConexoes, setPermitirConexoes] = useState(false)
  const [permitirPacotes, setPermitirPacotes] = useState(false)
  const [permitirConsumo, setPermitirConsumo] = useState(false)
  const [filtroConexoesMinimo, setFiltroConexoesMinimo] = useState('')
  const [filtroConexoesMaximo, setFiltroConexoesMaximo] = useState('')
  const [filtroPacotes, setFiltroPacotes] = useState('')
  const [filtroPacotesMinimo, setFiltroPacotesMinimo] = useState('')
  const [filtroPacotesMaximo, setFiltroPacotesMaximo] = useState('')
  const [filtroStatusContrato, setFiltroStatusContrato] = useState('')
  const [filtroSaldoMinimo, setFiltroSaldoMinimo] = useState('')
  const [filtroSaldoMaximo, setFiltroSaldoMaximo] = useState('')
  const [filtroValorMinimo, setFiltroValorMinimo] = useState('')
  const [filtroValorMaximo, setFiltroValorMaximo] = useState('')
  const [permitirDiasMinimo, setPermitirDiasMinimo] = useState('')
  const [permitirValorMinimo, setPermitirValorMinimo] = useState('')
  const [permitirValorMaximo, setPermitirValorMaximo] = useState('')
  const [permitirDiasrMaximo, setPermitirDiasMaximo] = useState('')
  const [permitirSaldoMinimo, setPermitirSaldoMinimo] = useState('')
  const [permitirSaldoMaximo, setPermitirSaldoMaximo] = useState('')
  const [permitirIccidMinimo, setPermitirIcciMinimo] = useState('')
  const [permitirIccidMaximo, setPermitirIcciMaximo] = useState('')
  const [filtroDiasUtilizadosMinimo, setFiltroDiasUtilizadosMinimo] =
    useState('')
  const [filtroDiasUtilizadosMaximo, setFiltroDiasUtilizadosMaximo] =
    useState('')
  const [filtroIccidMinimo, setFiltroIccidMinimo] = useState('')
  const [filtroIccidMaximo, setFiltroIccidMaximo] = useState('')
  const [filtroSituacaoContrato, setFiltroSituacaoContrato] = useState('')
  const [filtroArquivo, setFiltroArquivo] = useState('')
  const [tiposSimcard, setTiposSimcard] = useState([])
  const [renderizar, setRenderizar] = useState(false)
  const [renderOperadora, setRenderOperadora] = useState(false)
  const [renderOperadoraParte2Verificar, setRenderOperadoraParte2Verificar] =
    useState(false)
  const [renderOperadoraParte2, setRenderOperadoraParte2] = useState(false)
  const [nomeContrato, setNomeContrato] = useState('Selecione')
  const [cdContrato, setCdContrato] = useState()
  const [revelarSelectContrato, setRevelarSelectContrato] = useState(false)

  const [renderFormatarColunas, setFormatarColunas] = useState(false)
  const [renderTipoSimcard, setRenderTipoSimcard] = useState(false)
  const [renderTipoSimcardParte2, setRenderTipoSimcardParte2] = useState(false)
  const [renderTipoSimcardParte2Verificar, setTipoSimcardParte2Verificar] =
    useState(false)
  const [renderTipoSimcardParte3, setRenderTipoSimcardParte3] = useState(false)
  const [
    renderTipoSimcardParte3Verificar,
    setRenderTipoSimcardParte3Verificar
  ] = useState(false)
  const [renderTipoSimcardParte4, setRenderTipoSimcardParte4] = useState(false)
  const [
    renderTipoSimcardParte4Verificar,
    setRenderTipoSimcardParte4Verificar
  ] = useState(false)

  // autocomplete Serviços Adicionais
  const [dsServicoAdicional, setDsServicoAdicional] = useState('Selecione')
  const [cdServicoAdicional, setCdServicoAdicional] = useState('Selecione')
  const [valorPacote, setValorPacote] = useState('')
  const [revelarSelectServicoAdicional, setRevelarSelectServicoAdicional] =
    useState(false)

  const [filtroMarca, setFiltroMarca] = useState('Selecione')
  const [filtroModelo, setFiltroModelo] = useState('Selecione')
  const [filtroClienteFinal, setFiltroClienteFinal] = useState('')
  const [filtroNomeClienteFinal, setFiltroNomeClienteFinal] = useState('')
  //HOOKS DOS SELECTS
  const [dummy, setDummy] = useState('')
  const [revelarSelectMarca, setRevelarSelectMarca] = useState(false)
  const [revelarSelectModelo, setRevelarSelectModelo] = useState(false)
  const [revelarSelectGrupo, setRevelarSelectGrupo] = useState(false)
  const [checkAutomStatus, setCheckAutomStatus] = useState(true)
  const [checkAutomTrafego, setCheckAutomTrafego] = useState(true)
  const [checkAutomTecnologia, setCheckAutomTecnologia] = useState(true)
  const [checkAutomPlano, setCheckAutomPlano] = useState(true)
  const [checkAutomSMS, setCheckAutomSMS] = useState(true)
  const [checkAutomSIMCard, setCheckAutomSIMCard] = useState(true)
  const [checkAutomPacote, setCheckAutomPacote] = useState(true)
  const [resetContrato, setResetContrato] = useState(false)
  const [resetGrupo, setResetGrupo] = useState(false)
  const [resetMarca, setResetMarca] = useState(false)
  const [resetModelo, setResetModelo] = useState(false)
  const [avisoValor, setAvisoValor] = useState(false)
  const [avisoDias, setAvisoDias] = useState(false)
  const [avisoSaldo, setAvisoSaldo] = useState(false)
  const [avisoConexoes, setAvisoConexoes] = useState(false)
  const [avisoPacotes, setAvisoPacotes] = useState(false)
  const [avisoConsumo, setAvisoConsumo] = useState(false)

  useEffect(() => {
    //Check se todas as variaveis estao limpas
    function handlerCheckarTudoLimpo() {
      // verificador = []

      // SALDO
      if (
        (filtroSaldoMinimo == '' && filtroSaldoMaximo != '') ||
        (filtroSaldoMinimo != '' && filtroSaldoMaximo == '')
      ) {
        return false
      }
      // VALOR
      // if( ((filtroValorMinimo=='') && (filtroValorMaximo!='')) || ((filtroValorMinimo!='') && (filtroValorMaximo=='')) ) {
      //     return false
      // }
      // PACOTE
      if (
        (filtroPacotes != '' &&
          filtroPacotesMinimo == '' &&
          filtroPacotesMaximo == '') ||
        (filtroPacotes != '' &&
          filtroPacotesMinimo != '' &&
          filtroPacotesMaximo == '') ||
        (filtroPacotes != '' &&
          filtroPacotesMinimo == '' &&
          filtroPacotesMaximo != '') ||
        (filtroPacotes == '' &&
          filtroPacotesMinimo != '' &&
          filtroPacotesMaximo == '') ||
        (filtroPacotes == '' &&
          filtroPacotesMinimo == '' &&
          filtroPacotesMaximo != '') ||
        (filtroPacotes == '' &&
          filtroPacotesMinimo != '' &&
          filtroPacotesMaximo != '')
      ) {
        return false
      }
      // CONEXÕES
      if (
        (filtroConexoes != '' &&
          filtroConexoesMinimo == '' &&
          filtroConexoesMaximo == '') ||
        (filtroConexoes != '' &&
          filtroConexoesMinimo != '' &&
          filtroConexoesMaximo == '') ||
        (filtroConexoes != '' &&
          filtroConexoesMinimo == '' &&
          filtroConexoesMaximo != '') ||
        (filtroConexoes == '' &&
          filtroConexoesMinimo != '' &&
          filtroConexoesMaximo == '') ||
        (filtroConexoes == '' &&
          filtroConexoesMinimo == '' &&
          filtroConexoesMaximo != '') ||
        (filtroConexoes == '' &&
          filtroConexoesMinimo != '' &&
          filtroConexoesMaximo != '')
      ) {
        return false
      }
      // CONSUMO
      if (
        (filtroConsumo != '' &&
          filtroConsumoMinimo == '' &&
          filtroConsumoMaximo == '') ||
        (filtroConsumo != '' &&
          filtroConsumoMinimo != '' &&
          filtroConsumoMaximo == '') ||
        (filtroConsumo != '' &&
          filtroConsumoMinimo == '' &&
          filtroConsumoMaximo != '') ||
        (filtroConsumo == '' &&
          filtroConsumoMinimo != '' &&
          filtroConsumoMaximo == '') ||
        (filtroConsumo == '' &&
          filtroConsumoMinimo == '' &&
          filtroConsumoMaximo != '') ||
        (filtroConsumo == '' &&
          filtroConsumoMinimo != '' &&
          filtroConsumoMaximo != '')
      ) {
        return false
      }

      if (filtroOperadora.length > 0) {
        return true
      }
      if (filtroValorPlano.length > 0) {
        return true
      }
      if (filtroTecnologia != '') {
        return true
      }
      if (filtroTipoSimcard != '') {
        return true
      }
      if (filtroPlanoTipo != '') {
        return true
      }
      if (filtroPacoteExtra != '') {
        return true
      }
      if (filtroSituacaoContrato != '') {
        return true
      }
      if (filtroGrupo != '') {
        return true
      }
      if (nomeContrato != '') {
        return true
      }
      if (filtroEnvioSms != '') {
        return true
      }
      if (filtroPlanoMinimo != '') {
        return true
      }
      if (filtroPlanoMaximo != '') {
        return true
      }
      // if(filtroUltimaConexao=='') verificador.push(1)
      if (
        filtroUltimaConexaoDataInicio != '' ||
        filtroUltimaConexaoDataFinal != ''
      ) {
        return true
      }
      if (filtroAquisicaoDataInicio != '' || filtroAquisicaoDataFinal != '') {
        return true
      }
      if (filtroAtivacaoDataInicio != '' || filtroAtivacaoDataFinal == '') {
        return true
      }
      if (filtroTrafego != '') {
        return true
      }
      if (filtroStatusContrato != '') {
        return true
      }
      if (
        filtroDiasUtilizadosMinimo != '' ||
        filtroDiasUtilizadosMaximo != ''
      ) {
        return true
      }
      if (filtroIccidMinimo != '' || filtroIccidMaximo != '') {
        return true
      }
      if (filtroArquivo != '') {
        return true
      }
      // Se chegou até aqui, verifica se um dos 4 vampos está preenchido, se não retorna false
      if (
        filtroSaldoMinimo != '' ||
        filtroPacotes != '' ||
        filtroConexoes != '' ||
        filtroConsumo != ''
      ) {
        return true
      } else {
        return false
      }
    }

    async function handlerAtivarBotoes() {
      if (
        !avisoValor &&
        !avisoDias &&
        !avisoSaldo &&
        !avisoConexoes &&
        !avisoPacotes &&
        !avisoConsumo
      ) {
        //se for alterado algo vai abilitar os botoes
        if (
          props.primeiraVez &&
          !props.foiLimpado &&
          (await handlerCheckarTudoLimpo())
        ) {
          props.setFoiSelecionado(true)
        } else if (
          props.primeiraVez &&
          !props.foiLimpado &&
          !(await handlerCheckarTudoLimpo())
        ) {
          props.setFoiSelecionado(false)
        }
      }
    }

    //limpa os dados de todas as variaveis
    if (props.foiLimpado) {
      setFiltroOperadora([])
      setFiltroValorPlano([])
      setFiltroTecnologia('')
      setFiltroTipoSimcard('')
      setFiltroPlanoTipo('')
      setFiltroPacoteExtra('')
      setFiltroEnvioSms('')
      setFiltroPlanoMinimo('')
      setFiltroPlanoMaximo('')
      setFiltroUltimaConexao('')
      setFiltroUltimaConexaoDataInicio('')
      setFiltroUltimaConexaoDataFinal('')
      setFiltroAquisicaoDataInicio('')
      setFiltroAquisicaoDataFinal('')
      setFiltroAtivacaoDataInicio('')
      setFiltroAtivacaoDataFinal('')
      setFiltroConsumo('')
      setFiltroConsumoMinimo('')
      setFiltroConsumoMaximo('')
      setFiltroTrafego('')
      setFiltroConexoes('')
      setFiltroConexoesMinimo('')
      setFiltroConexoesMaximo('')
      setFiltroPacotes('')
      setFiltroPacotesMinimo('')
      setFiltroPacotesMaximo('')
      setFiltroSaldoMinimo('')
      setFiltroSaldoMaximo('')
      setFiltroValorMinimo('')
      setFiltroValorMaximo('')
      setFiltroStatusContrato('')
      setFiltroDiasUtilizadosMinimo('')
      setFiltroDiasUtilizadosMaximo('')
      setFiltroArquivo('')
      // setFiltroGrupo('')
      setFiltroSituacaoContrato('')
      setFiltroIccidMinimo('')
      setFiltroIccidMaximo('')
      setFiltroClienteFinal('')
      props.setFoiLimpado(false)
    }

    handlerAtivarBotoes()
    props.setPrimeiraVez(true)
  }, [
    renderizar,
    filtroOperadora,
    filtroValorPlano,
    filtroTecnologia,
    filtroTipoSimcard,
    filtroPlanoTipo,
    filtroPacoteExtra,
    filtroEnvioSms,
    filtroPlanoMinimo,
    filtroPlanoMaximo,
    filtroUltimaConexao,
    filtroUltimaConexaoDataInicio,
    filtroUltimaConexaoDataFinal,
    filtroAquisicaoDataInicio,
    filtroAquisicaoDataFinal,
    filtroAtivacaoDataInicio,
    filtroAtivacaoDataFinal,
    filtroConsumo,
    filtroConsumoMinimo,
    filtroConsumoMaximo,
    filtroTrafego,
    filtroConexoes,
    filtroConexoesMinimo,
    filtroConexoesMaximo,
    filtroPacotes,
    filtroPacotesMinimo,
    filtroPacotesMaximo,
    filtroSaldoMinimo,
    filtroSaldoMaximo,
    filtroValorMinimo,
    filtroValorMaximo,
    filtroDiasUtilizadosMinimo,
    filtroDiasUtilizadosMaximo,
    filtroGrupo,
    nomeContrato,
    filtroSituacaoContrato,
    filtroArquivo,
    filtroMarca,
    filtroModelo,
    filtroIccidMinimo,
    filtroIccidMaximo,
    filtroClienteFinal,
    props.foiLimpado
  ])

  function validarValor(valor, campo) {
    // valida se os valores do máximo é maior que os do mínimo
    let numInteiro = parseInt(valor, 10)
    let numInteiro2
    if (campo === 'campo-texto-valor-maximo') {
      numInteiro2 = parseInt(filtroValorMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoValor(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoValor(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-dias-maximo') {
      numInteiro2 = parseInt(filtroDiasUtilizadosMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoDias(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoDias(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-saldo-maximo') {
      numInteiro2 = parseInt(filtroSaldoMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoSaldo(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoSaldo(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-conexoes-maximo') {
      numInteiro2 = parseInt(filtroConexoesMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoConexoes(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoConexoes(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-pacotes-maximo') {
      numInteiro2 = parseInt(filtroPacotesMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoPacotes(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoPacotes(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-consumo-maximo') {
      numInteiro2 = parseInt(filtroConsumoMinimo, 10)
      if (numInteiro < numInteiro2) {
        setAvisoConsumo(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoConsumo(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-valor-minimo') {
      numInteiro2 = parseInt(filtroValorMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoValor(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoValor(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-dias-minimo') {
      numInteiro2 = parseInt(filtroDiasUtilizadosMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoDias(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoDias(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-saldo-minimo') {
      numInteiro2 = parseInt(filtroSaldoMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoSaldo(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoSaldo(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-conexoes-minimo') {
      numInteiro2 = parseInt(filtroConexoesMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoConexoes(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoConexoes(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-pacotes-minimo') {
      numInteiro2 = parseInt(filtroPacotesMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoPacotes(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoPacotes(false)
        props.setFoiSelecionado(true)
      }
    } else if (campo === 'campo-texto-consumo-minimo') {
      numInteiro2 = parseInt(filtroConsumoMaximo, 10)
      if (numInteiro2 < numInteiro) {
        setAvisoConsumo(true)
        props.setFoiSelecionado(false)
      } else {
        setAvisoConsumo(false)
        props.setFoiSelecionado(true)
      }
    }
  }

  function validarNumero(id, valor) {
    const filtro = /^[0-9]+$/
    if (valor === '' || filtro.test(valor)) {
      if (id === 'campo-texto-plano-minimo') {
        setFiltroPlanoMinimo(valor)
      }
      if (id === 'campo-texto-plano-maximo') {
        setFiltroPlanoMaximo(valor)
      }
      if (id === 'campo-texto-consumo-minimo') {
        setFiltroConsumoMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-consumo-maximo') {
        setFiltroConsumoMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-conexoes-minimo') {
        setFiltroConexoesMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-conexoes-maximo') {
        setFiltroConexoesMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-pacotes-minimo') {
        setFiltroPacotesMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-pacotes-maximo') {
        setFiltroPacotesMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-saldo-minimo') {
        setPermitirSaldoMinimo(true)
        setFiltroSaldoMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-saldo-maximo') {
        setPermitirSaldoMaximo(true)
        setFiltroSaldoMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-valor-minimo') {
        setPermitirValorMinimo(true)
        setFiltroValorMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-valor-maximo') {
        setPermitirValorMaximo(true)
        setFiltroValorMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-dias-minimo') {
        setPermitirDiasMinimo(true)
        setFiltroDiasUtilizadosMinimo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-dias-maximo') {
        setPermitirDiasMaximo(true)
        setFiltroDiasUtilizadosMaximo(valor)
        validarValor(valor, id)
      }
      if (id === 'campo-texto-iccid-minimo') {
        setPermitirIcciMinimo(true)
        setFiltroIccidMinimo(valor)
      }
      if (id === 'campo-texto-iccid-maximo') {
        setPermitirIcciMaximo(true)
        setFiltroIccidMaximo(valor)
      }
      if (id === 'campo-texto-cliente-final') {
        setFiltroClienteFinal(valor)
      }
    }
  }

  async function handleAlterarOperadora(codigo, limpar) {
    setResetContrato(true)
    setResetGrupo(true)
    setResetMarca(true)
    setResetModelo(true)
    let validadorRetirado = false
    let filtroOperadoraTemp = JSON.parse(JSON.stringify(filtroOperadoratt))

    if (limpar) {
      let inputs = $('input[type=checkbox]') // TODO: alterar essa linha para TIRAR biblioteca jquery ?
      inputs.attr('checked', false)
      inputs.prop('checked', false)
      filtroOperadoraTemp = []
      filtroOperadoratt = []
      setFiltroOperadora([])
      return
    }

    await Promise.all(
      filtroOperadoratt.map((item, i) => {
        if (item == codigo) {
          filtroOperadoraTemp.splice(i, 1)
          validadorRetirado = true
        }
      })
    )
    filtroOperadoraTemp.push(codigo.toString())
    if (validadorRetirado) {
      filtroOperadoraTemp = filtroOperadoraTemp.filter(
        (operadoraCodigo) => operadoraCodigo != codigo
      )
    }

    filtroOperadoratt = JSON.parse(JSON.stringify(filtroOperadoraTemp))
    setFiltroOperadora(filtroOperadoratt)
  }

  // async function handleAlterarValorPlano(codigo, descricao) {
  //     let validadorRetirado = false
  //     let filtroValorTemp = JSON.parse(JSON.stringify(filtroValorPlanott))
  //     await Promise.all(filtroValorPlanott.map((item, i) => {
  //         if (item == codigo) {
  //             filtroValorTemp.splice(i, 1)
  //             validadorRetirado = true
  //         }
  //     }))
  //     if (validadorRetirado) {
  //         filtroValorPlanott = JSON.parse(JSON.stringify(filtroValorTemp))
  //         setFiltroValorPlano(JSON.parse(JSON.stringify(filtroValorTemp)))
  //     }
  //     if (!validadorRetirado) {
  //         filtroValorTemp.push(codigo)
  //         filtroValorPlanott = JSON.parse(JSON.stringify(filtroValorTemp))
  //         setFiltroValorPlano(JSON.parse(JSON.stringify(filtroValorTemp)))
  //     }
  // }

  async function renderCheckboxesOperadoras(
    arrDadosOperadoras,
    noOperadorasInLocalStorage
  ) {
    const qtdOperadoras = arrDadosOperadoras.length

    if (qtdOperadoras <= 3) {
      await checkboxesPoucasOperadoras(arrDadosOperadoras)
    } else {
      let hasOnInput = false
      if (noOperadorasInLocalStorage) hasOnInput = true

      await checkboxesMuitasOperadoras(arrDadosOperadoras, hasOnInput)
    }
  }

  async function checkboxesPoucasOperadoras(arrDadosOperadoras) {
    let objetoServico = await Promise.all(
      arrDadosOperadoras.map(async (operadora) => {
        return (
          <CheckboxOperadora
            key={operadora.cd_dsp_operadora}
            onChange={() => handleAlterarOperadora(operadora.cd_dsp_operadora)}
            operadora={operadora}
            defaultChecked={await handlerCheckBox(
              'OPERADORA',
              operadora.cd_dsp_operadora
            )}
          />
        )
      })
    )
    setRenderOperadora(objetoServico)
  }

  async function checkboxesMuitasOperadoras(arrDadosOperadoras, hasOnInput) {
    const qtdOperadoras = arrDadosOperadoras.length

    let objetoServico = await Promise.all(
      arrDadosOperadoras.slice(0, 4).map(async (operadora) => {
        const onChange = () =>
          handleAlterarOperadora(operadora.cd_dsp_operadora)
        const restProps = {}

        if (hasOnInput) {
          restProps.onInput = onChange
        } else {
          restProps.onChange = onChange
        }

        return (
          <CheckboxOperadora
            key={operadora.cd_dsp_operador}
            defaultChecked={await handlerCheckBox(
              'OPERADORA',
              operadora.cd_dsp_operadora
            )}
            operadora={operadora}
            {...restProps}
          />
        )
      })
    )

    setRenderOperadora(objetoServico)

    let objetoServico2 = await Promise.all(
      arrDadosOperadoras.slice(4).map(async (operadora) => {
        return (
          <CheckboxOperadora
            key={operadora.cd_dsp_operador}
            onChange={() => handleAlterarOperadora(operadora.cd_dsp_operadora)}
            operadora={operadora}
            defaultChecked={await handlerCheckBox(
              'OPERADORA',
              operadora.cd_dsp_operadora
            )}
          />
        )
      })
    )

    if (qtdOperadoras > 3) {
      setFormatarColunas(true)
    }

    setRenderOperadoraParte2(objetoServico2)
    setRenderOperadoraParte2Verificar(true)
  }

  function getDadosOperadora() {
    const dadosOperadora = JSON.parse(localStorage.getItem('keyOperadora'))

    return dadosOperadora.data.dados
  }

  useEffect(() => {
    async function renderOperadora() {
      setSpinAtivo(true)

      if (!localStorage.hasOwnProperty('keyOperadora')) {
        const operadorasPadroes = await api.get(
          '/m1/consultar/operadora/operadora_cliente_simcard?cd_pav_cliente=' +
            parseInt(decryptJWT('codigoCliente'))
        )
        setDadoSelectOperadora(operadorasPadroes.data.dados)
        localStorage.setItem('keyOperadora', JSON.stringify(operadorasPadroes))

        const arrDadosOperadoras = getDadosOperadora()
        await renderCheckboxesOperadoras(arrDadosOperadoras, true)
      } else {
        let arrDadosOperadoras = getDadosOperadora()

        setDadoSelectOperadora(arrDadosOperadoras)

        await renderCheckboxesOperadoras(arrDadosOperadoras)
      }

      setSpinAtivo(false)
    }
    renderOperadora()
  }, [])

  // useEffect(() => {
  //     async function renderContrato() {

  //         let dsContratos = [];
  //         let idContratos = [];
  //         let JSONcontratos = [];
  //         // carrega os contratos do cliente
  //         const { data } = await api.get('/m1/consultar/simcard_opcoes_filtros?codigo_cliente=' + decryptJWT('codigoCliente'))
  //         if (data.status == 200) {
  //             await data.dados[0].CD_SITUACAO.split(";").map((item, i) => {
  //                 idContratos.push(item);
  //             })
  //             await data.dados[0].SITUACAO.split(";").map((item, i) => {
  //                 dsContratos.push(item);
  //             })
  //             dsContratos.map((item, i) => {
  //                 JSONcontratos.push({ SITUACAO: dsContratos[i].slice(0, 1).toUpperCase() + dsContratos[i].slice(1, dsContratos[i].length).toLowerCase(), CD_SITUACAO: idContratos[i] })
  //             })
  //             JSONcontratos.push({ SITUACAO: "Todos", CD_SITUACAO: "Todos" })
  //         }
  //         setDadosSelectContratos(JSONcontratos)

  //         if (JSONcontratos.length <= 3) {
  //             let objetoServico = await Promise.all(JSONcontratos.slice(0, 3).map(async (contrato) => {
  //                 return (
  //                     <>
  //                         <label className="label-radio-filtros-2 fonte-cor-16" onChange={() => setFiltroSituacaoContrato(contrato.SITUACAO + "*" + contrato.CD_SITUACAO)}>{contrato.SITUACAO}
  //                             <input defaultChecked={handlerCheck("CD_SITUACAO", contrato.SITUACAO + "*" + contrato.CD_SITUACAO)} type="radio" name="CD_SITUACAO" value={contrato.SITUACAO + "*" + contrato.CD_SITUACAO} />
  //                             <span className="checkbox-filtros-b campo-texto-cor-3"></span>
  //                         </label>
  //                     </>
  //                 );
  //             }))
  //             setRenderContrato(objetoServico)
  //         }
  //         if (JSONcontratos.length > 3) {
  //             let objetoServico = await Promise.all(JSONcontratos.slice(0, 3).map(async (contrato) => {
  //                 return (
  //                     <>
  //                         <label className="label-radio-filtros-2 fonte-cor-16" onChange={() => setFiltroSituacaoContrato(contrato.SITUACAO + "*" + contrato.CD_SITUACAO)}>{contrato.SITUACAO}
  //                             <input defaultChecked={handlerCheck("CD_SITUACAO", contrato.SITUACAO + "*" + contrato.CD_SITUACAO)} type="radio" name="CD_SITUACAO" value={contrato.SITUACAO + "*" + contrato.CD_SITUACAO} />
  //                             <span className="checkbox-filtros-b campo-texto-cor-3"></span>
  //                         </label>
  //                     </>
  //                 );
  //             }))
  //             setRenderContrato(objetoServico)
  //             let objetoServico2 = await Promise.all(JSONcontratos.slice(3).map(async (contrato) => {
  //                 return (
  //                     <>
  //                         <label className="label-radio-filtros-2 fonte-cor-16" onChange={() => setFiltroSituacaoContrato(contrato.SITUACAO + "*" + contrato.CD_SITUACAO)}>{contrato.SITUACAO}
  //                             <input defaultChecked={handlerCheck("CD_SITUACAO", contrato.SITUACAO + "*" + contrato.CD_SITUACAO)} type="radio" name="CD_SITUACAO" value={contrato.SITUACAO + "*" + contrato.CD_SITUACAO} />
  //                             <span className="checkbox-filtros-b campo-texto-cor-3"></span>
  //                         </label>
  //                     </>
  //                 );
  //             }))
  //             setRenderContratoParte2(objetoServico2)
  //             setRenderContratoParte2Verificar(true)
  //         }

  //     }
  //     renderContrato()
  // }, [])

  useEffect(() => {
    async function renderTipoSimcard() {
      const { data } = await api.get('/m1/consultar/simcard_tipo')
      if (data.status == 200) {
        setTiposSimcard(data.dados)
      }

      if (data.dados) {
        let objetoServico = await Promise.all(
          data.dados.slice(0, 1).map(async (item_tiposSimcard) => {
            return (
              <React.Fragment key={item_tiposSimcard.ds_simcard_tipo}>
                <label
                  className="label-radio-filtros-simcard fonte-cor-16"
                  onChange={() =>
                    setFiltroTipoSimcard(item_tiposSimcard.ds_simcard_tipo)
                  }
                >
                  {item_tiposSimcard.ds_simcard_tipo}
                  <input
                    defaultChecked={handlerCheck(
                      'TIPO_SIMCARD',
                      item_tiposSimcard.ds_simcard_tipo
                    )}
                    type="radio"
                    id={'TIPO_SIMCARD_' + item_tiposSimcard.ds_simcard_tipo}
                    name="TIPO_SIMCARD"
                    value={item_tiposSimcard.ds_simcard_tipo}
                  />
                  <span className="checkbox-filtros-b campo-texto-cor-3" />
                </label>
              </React.Fragment>
            )
          })
        )
        setRenderTipoSimcard(objetoServico)
      }
      if (data.dados.length > 0) {
        let objetoServico2 = await Promise.all(
          data.dados.slice(1, 2).map(async (item_tiposSimcard) => {
            return (
              <React.Fragment key={item_tiposSimcard.ds_simcard_tipo}>
                <label
                  className="label-radio-filtros-2 fonte-cor-16"
                  onChange={() =>
                    setFiltroTipoSimcard(item_tiposSimcard.ds_simcard_tipo)
                  }
                >
                  {item_tiposSimcard.ds_simcard_tipo}
                  <input
                    defaultChecked={handlerCheck(
                      'TIPO_SIMCARD',
                      item_tiposSimcard.ds_simcard_tipo
                    )}
                    type="radio"
                    id={'TIPO_SIMCARD_' + item_tiposSimcard.ds_simcard_tipo}
                    name="TIPO_SIMCARD"
                    value={item_tiposSimcard.ds_simcard_tipo}
                  />
                  <span className="checkbox-filtros-b campo-texto-cor-3" />
                </label>
              </React.Fragment>
            )
          })
        )
        setRenderTipoSimcardParte2(objetoServico2)
        setTipoSimcardParte2Verificar(true)
        let objetoServico4 = await Promise.all(
          data.dados.slice(2, 3).map(async (item_tiposSimcard) => {
            return (
              <React.Fragment key={item_tiposSimcard.ds_simcard_tipo}>
                <label
                  className="label-radio-filtros-simcard fonte-cor-16"
                  onChange={() =>
                    setFiltroTipoSimcard(item_tiposSimcard.ds_simcard_tipo)
                  }
                >
                  {item_tiposSimcard.ds_simcard_tipo}
                  <input
                    defaultChecked={handlerCheck(
                      'TIPO_SIMCARD',
                      item_tiposSimcard.ds_simcard_tipo
                    )}
                    type="radio"
                    id={'TIPO_SIMCARD_' + item_tiposSimcard.ds_simcard_tipo}
                    name="TIPO_SIMCARD"
                    value={item_tiposSimcard.ds_simcard_tipo}
                  />
                  <span className="checkbox-filtros-b campo-texto-cor-3" />
                </label>
              </React.Fragment>
            )
          })
        )
        setRenderTipoSimcardParte4(objetoServico4)
        setTipoSimcardParte2Verificar(true)
        let objetoServico3 = await Promise.all(
          data.dados.slice(4).map(async (item_tiposSimcard) => {
            return (
              <React.Fragment key={item_tiposSimcard.ds_simcard_tipo}>
                <label
                  className="label-radio-filtros-2 fonte-cor-16"
                  onChange={() =>
                    setFiltroTipoSimcard(item_tiposSimcard.ds_simcard_tipo)
                  }
                >
                  {item_tiposSimcard.ds_simcard_tipo}
                  <input
                    defaultChecked={handlerCheck(
                      'TIPO_SIMCARD',
                      item_tiposSimcard.ds_simcard_tipo
                    )}
                    type="radio"
                    id={'TIPO_SIMCARD_' + item_tiposSimcard.ds_simcard_tipo}
                    name="TIPO_SIMCARD"
                    value={item_tiposSimcard.ds_simcard_tipo}
                  />
                  <span className="checkbox-filtros-b campo-texto-cor-3" />
                </label>
              </React.Fragment>
            )
          })
        )
        setRenderTipoSimcardParte3(objetoServico3)
        if (data.dados.length > 3) {
          setRenderTipoSimcardParte3Verificar(true)
        }
        setRenderTipoSimcardParte3(objetoServico3)
        if (data.dados.length > 2) {
          setRenderTipoSimcardParte4Verificar(true)
        }
      }
    }
    renderTipoSimcard()
  }, [])

  useEffect(() => {
    if (filtros.vazio || !filtros.OPERADORA) {
      filtroOperadoratt = []
    }
  }, [])

  // useEffect(() => {
  //     async function renderValorPlano(){
  //         const valorPlano = await api.get('/m1/consultar/simcard/valor_plano_simcard?codigo_cliente='+decryptJWT("codigoCliente"))
  //         if(valorPlano.data.dados.length > 0){
  //             let objetoServico = await Promise.all( valorPlano.data.dados.map(async(operadora) => {
  //                 return (
  //                     <>
  //                         <label className="label-checkbox-filtros-operadoras fonte-cor-1" onChange={()=> handleAlterarValorPlano(operadora.n2_valor)}>{"R$ "+formatarMilhar(operadora.n2_valor)}
  //                             <input defaultChecked={await handlerCheckBox("VALOR_PLANO",operadora.n2_valor)} type="checkbox" name={"VALOR_PLANO"} value={operadora.n2_valor}/>
  //                             <span className="checkbox-filtros-operadoras campo-texto-cor-3"></span>
  //                         </label>
  //                         <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
  //                     </>
  //                 );
  //             }))
  //             setRenderValorPlano(objetoServico)
  //         }
  //     }
  //     renderValorPlano()

  // }, [])

  function converterNumeroBarraPesquisa(numero) {
    let aux = numero.split(',')
    let numeroAux = []
    aux.map((item) => {
      item = parseFloat(item).toFixed(2)
      numeroAux.push(' ' + item.replace('.', ','))
    })

    numeroAux.pop()
    return numeroAux
  }

  async function handleCancelar() {
    props.setRevelarDropFiltros(false)
  }

  async function handleLimpar() {
    setCheckAutomStatus(true)
    setCheckAutomTecnologia(true)
    setCheckAutomPlano(true)
    setCheckAutomSMS(true)
    setCheckAutomSIMCard(true)
    setCheckAutomPacote(true)
    setCheckAutomTrafego(true)
    setNomeContrato('Selecione')
    setFiltroGrupo('Selecione')
    setFiltroMarca('Selecione')
    setFiltroModelo('Selecione')
    setDsServicoAdicional('Selecione')
    setPermitirConexoes(false)
    setPermitirPacotes(false)
    setPermitirConsumo(false)
    setAvisoConexoes(false)
    setAvisoConsumo(false)
    setAvisoDias(false)
    setAvisoPacotes(false)
    setAvisoSaldo(false)
    setAvisoValor(false)
  }

  const filterModalFormRow = {
    maxWidth: '917px'
  }

  function handleClienteFinalNome(value) {
    setFiltroNomeClienteFinal(value)
  }

  return (
    <Form
      ref={props.modal}
      onSubmit={props.enviarFiltros}
      className="drop-acoes-container-filtro"
    >
      {spinAtivo && (
        <div className="componente-spinner-overlay-abrir-filtro-simcard">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <div className="campo-select-triangulo-menor-cor-2-simcard" />
      <div className="drop-acoes-corpo-filtro fundo-cor-13">
        <div className="modal-filtro-simcard fundo-cor-13">
          <Form.Row style={filterModalFormRow}>
            <Col>
              <img
                className="icone-label-janela nao-selecionavel"
                src={elipseVerde()}
                alt="elipse"
              />
              <Form.Label>
                <div className="fonte-cor-1 label-janela">Filtros</div>
              </Form.Label>
              <Button
                className="botao-fechar-filtro"
                variant="danger"
                onClick={() => handleCancelar()}
              >
                VOLTAR
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleLimpar()
                  props.limparFiltros()
                  handleAlterarOperadora('', true)
                }}
                className="botao-limpar-filtro-simcard fonte-cor-12"
                variant="default"
              >
                LIMPAR FILTROS
                <img
                  src={limparFiltr()}
                  alt="fechar-janela"
                  className="icone-menu-filtro"
                />
              </Button>
              {/* <Button className="botao-mostrar-filtro-arquivo fonte-cor-13" variant="danger" onClick={() => setRenderizarFiltroArquivo(true)} >
                                FILTRAR POR ARQUIVO
                                <img src={filtroArq()} alt="filtroArquivo" className="icone-menu-filtro-arquivo" />
                            </Button>
                            {renderizarFiltroArquivo &&
                                <div className="componente-modal-overlay-filtro-arquivo-simcard">
                                    <div className="componente-modal-filtro-arquivo-container">
                                        <FiltroArquivo
                                            renderizar={renderizarFiltroArquivo}
                                            setRenderizar={setRenderizarFiltroArquivo}
                                            handleFuncaoEstadosRedux={handleFuncaoEstadosRedux}
                                            enviarFiltros={props.enviarFiltros}
                                            arrayFiltrosSimcard={arrayFiltrosSimcard}
                                            rota={"filtroSimcard"}
                                        />
                                    </div>
                                </div>
                            } */}

              {/* chama a função enviarFiltros */}
              <Button
                disabled={props.foiSelecionado ? false : true}
                type="submit"
                onClick={() => handleFuncaoEstadosRedux()}
                className={
                  props.foiSelecionado
                    ? 'botao-aplicar-filtro-simcard-habilitado fonte-cor-2 fonte-cor-4'
                    : 'botao-aplicar-filtro-simcard-disable'
                }
                variant="default"
              >
                FILTRAR
              </Button>
            </Col>
          </Form.Row>
          {!clientePortal ? (
            //SE O NIVEL DO CLIENTE FOR MENOR QUE 3 - NÃO É PORTAL CLIENTE.
            //MOSTRA O FILTRO SIMCARD NORMAL.
            <Form.Row class="filtro-conteudo-container-n1-n2">
              <Col className="conteudo-filtros-sicmards">
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards-2 fundo-cor-14">
                  <ListCheckboxOperadora
                    {...{
                      renderOperadora,
                      renderOperadoraParte2Verificar,
                      renderOperadoraParte2
                    }}
                  />
                  {/* ------------------- */}
                  {renderFormatarColunas ? (
                    <div className="coluna-alterar-ciclo-vida-1-status-b">
                      <Form.Label className="fonte-cor-1 label-campo-filtro">
                        Status{' '}
                      </Form.Label>
                      <div className="break-2" />
                      <Form.Group>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('online')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Online
                            {filtros.ULTIMA_CONEXAO == 'online' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-status-b fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('estoque')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Estoque
                            {filtros.ULTIMA_CONEXAO == 'estoque' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <did className="flex-filtros">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('ultima_conexao')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Offline
                            {filtros.ULTIMA_CONEXAO == 'ultima_conexao' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-status-b fonte-cor-16">
                            Todos
                            {checkAutomStatus && !filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                                checked
                              />
                            ) : checkAutomStatus && filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </did>
                      </Form.Group>
                    </div>
                  ) : (
                    <div
                      style={
                        renderOperadora.length < 4 ? { marginLeft: '0' } : {}
                      }
                      className="coluna-alterar-ciclo-vida-1-status"
                    >
                      <Form.Label className="fonte-cor-1 label-campo-filtro">
                        Status
                      </Form.Label>
                      <div className="break-2" />
                      <Form.Group>
                        <div className="flex-filtros-status">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('online')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Online
                            {filtros.ULTIMA_CONEXAO == 'online' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-status-b fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('estoque')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Estoque
                            {filtros.ULTIMA_CONEXAO == 'estoque' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <did className="flex-filtros-status">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('ultima_conexao')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Offline
                            {filtros.ULTIMA_CONEXAO == 'ultima_conexao' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-status-b fonte-cor-16">
                            Todos
                            {checkAutomStatus && !filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                                checked
                              />
                            ) : checkAutomStatus && filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </did>
                      </Form.Group>
                    </div>
                  )}
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-tec">
                    <Form.Label>
                      <div className="fonte-cor-1 label-campo-filtro">
                        Tecnologia
                      </div>
                    </Form.Label>
                    <div className="break-1" />
                    <Form.Group>
                      <div className="flex-filtros">
                        <label
                          className="label-radio-filtros-2 fonte-cor-16"
                          onChange={() => {
                            setFiltroTecnologia('2G')
                            setCheckAutomTecnologia(false)
                          }}
                        >
                          2G
                          {filtros.TECNOLOGIA == '2G' ? (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '2G')}
                              type="radio"
                              id="TECNOLOGIA_2G"
                              name="TECNOLOGIA"
                              value="2G"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '2G')}
                              type="radio"
                              id="TECNOLOGIA_2G"
                              name="TECNOLOGIA"
                              value="2G"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label
                          className="label-radio-filtros-tec fonte-cor-16"
                          onChange={() => {
                            setFiltroTecnologia('3G')
                            setCheckAutomTecnologia(false)
                          }}
                        >
                          3G
                          {filtros.TECNOLOGIA == '3G' ? (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '3G')}
                              type="radio"
                              id="TECNOLOGIA_3G"
                              name="TECNOLOGIA"
                              value="3G"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '3G')}
                              type="radio"
                              id="TECNOLOGIA_3G"
                              name="TECNOLOGIA"
                              value="3G"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-2 fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('4G')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            4G
                            {filtros.TECNOLOGIA == '4G' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  '4G'
                                )}
                                type="radio"
                                id="TECNOLOGIA_4G"
                                name="TECNOLOGIA"
                                value="4G"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  '4G'
                                )}
                                type="radio"
                                id="TECNOLOGIA_4G"
                                name="TECNOLOGIA"
                                value="4G"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-tec fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('NB')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            NB-IoT
                            {filtros.TECNOLOGIA == 'NB' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'NB'
                                )}
                                type="radio"
                                id="TECNOLOGIA_NB"
                                name="TECNOLOGIA"
                                value="NB"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'NB'
                                )}
                                type="radio"
                                id="TECNOLOGIA_NB"
                                name="TECNOLOGIA"
                                value="NB"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-2 fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('Outras')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            Outras
                            {filtros.TECNOLOGIA == 'Outras' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'OUTRAS'
                                )}
                                type="radio"
                                id="TECNOLOGIA_OUTRAS"
                                name="TECNOLOGIA"
                                value="OUTRAS"
                                checked
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'OUTRAS'
                                )}
                                type="radio"
                                id="TECNOLOGIA_OUTRAS"
                                name="TECNOLOGIA"
                                value="OUTRAS"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-tec fonte-cor-16">
                            Todos
                            {checkAutomTecnologia && !filtros.TECNOLOGIA ? (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                                checked
                              />
                            ) : checkAutomTecnologia && filtros.TECNOLOGIA ? (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="coluna-trafego-filtro">
                    <Form.Group>
                      <Form.Label className="fonte-cor-1 label-campo-filtro campo-trafego">
                        Tráfego{' '}
                      </Form.Label>
                      <div className="break-3" />
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-trafego-2 fonte-cor-16">
                          Ativo
                          {filtros.TRAFEGO == 'Ativo' ? (
                            <input
                              defaultChecked={handlerCheck('TRAFEGO', 'Ativo')}
                              type="radio"
                              name="TRAFEGO"
                              value="Ativo"
                              onChange={() => {
                                setFiltroTrafego('Ativo')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TRAFEGO', 'Ativo')}
                              type="radio"
                              name="TRAFEGO"
                              value="Ativo"
                              onChange={() => {
                                setFiltroTrafego('Ativo')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-trafego fonte-cor-16">
                          Bloqueado
                          {filtros.TRAFEGO == 'Bloqueado' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Bloqueado'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Bloqueado"
                              onChange={() => {
                                setFiltroTrafego('Bloqueado')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Bloqueado'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Bloqueado"
                              onChange={() => {
                                setFiltroTrafego('Bloqueado')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-trafego-2 fonte-cor-16">
                          Suspenso
                          {filtros.TRAFEGO == 'Suspenso' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Suspenso'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Suspenso"
                              onChange={() => {
                                setFiltroTrafego('Suspenso')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Suspenso'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Suspenso"
                              onChange={() => {
                                setFiltroTrafego('Suspenso')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-trafego fonte-cor-16">
                          Todos
                          {checkAutomTrafego && !filtros.TRAFEGO ? (
                            <input type="radio" name="TRAFEGO" checked />
                          ) : checkAutomTrafego && filtros.TRAFEGO ? (
                            <input type="radio" name="TRAFEGO" />
                          ) : (
                            <input type="radio" name="TRAFEGO" />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}

                  <div className="coluna-alterar-ciclo-vida-1-status-contrato">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro">
                      Status do contrato{' '}
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Control
                      name="STATUS_CONTRATO"
                      type="text"
                      value={
                        nomeContrato == 'Selecione' || resetContrato
                          ? ''
                          : nomeContrato
                      }
                      className="d-none"
                      onChange={(e) => setCdContrato(e.target.value)}
                    />
                    {revelarSelectContrato == false && (
                      <Button
                        className="campo-texto-cor-3 campo-select-contrato-filtro-b"
                        onClick={() =>
                          setRevelarSelectContrato(!revelarSelectContrato)
                        }
                      >
                        <div className="fonte-cor-16 label-campo-contrato-filtro-simcard">
                          {resetContrato
                            ? 'Selecione'
                            : nomeContrato
                              ? nomeContrato.split('*')[0]
                              : 'Selecione'}
                        </div>
                        <img
                          className={
                            revelarSelectContrato
                              ? 'campo-select-icone-ativado-filtro nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectContrato === true && (
                      <SelectStatusContrato
                        setRevelarSelectContrato={setRevelarSelectContrato}
                        setNomeContrato={setNomeContrato}
                        setCdContrato={setCdContrato}
                        setFiltroSituacaoContrato={setFiltroSituacaoContrato}
                        nomeContrato={nomeContrato}
                        rota="filtroSimcard"
                        setResetContrato={setResetContrato}
                      />
                    )}
                  </div>

                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-conexao">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Conexões{' '}
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-conexao-container">
                        <label className="label-radio-filtros-conexao fonte-cor-16">
                          No dia
                          {filtros.CONEXOES == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'dia')}
                              type="radio"
                              name="CONEXOES"
                              value="dia"
                              onChange={() => {
                                setFiltroConexoes('dia')
                                setPermitirConexoes(true)
                              }}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'dia')}
                              type="radio"
                              name="CONEXOES"
                              value="dia"
                              onChange={() => {
                                setFiltroConexoes('dia')
                                setPermitirConexoes(true)
                              }}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-2 fonte-cor-16">
                          No ciclo
                          {filtros.CONEXOES == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'ciclo')}
                              type="radio"
                              name="CONEXOES"
                              value="ciclo"
                              onChange={() => setFiltroConexoes('ciclo')}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'ciclo')}
                              type="radio"
                              name="CONEXOES"
                              value="ciclo"
                              onChange={() => setFiltroConexoes('ciclo')}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin-conexoes">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="CONEXOES_MIN"
                              className="filtros-texto-valor-mb-filtro-conexoes campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={
                                permitirConexoes ? filtroConexoesMinimo : ''
                              }
                              onChange={
                                permitirConexoes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-conexoes-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className=" fonte-cor-16 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="CONEXOES_MAX"
                              className="filtros-texto-valor-mb-filtro campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={
                                permitirConexoes ? filtroConexoesMaximo : ''
                              }
                              onChange={
                                permitirConexoes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-conexoes-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-16 campo-texto-cor-3" />
                          </div>
                        </div>
                        {avisoConexoes && (
                          <Form.Label className="label-campo-conexao-invalido">
                            Máx. deve ser maior que Min.
                          </Form.Label>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ------------------- */}
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards-2 fundo-cor-14">
                  <div className="coluna-alterar-ciclo-vida-1-dias">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-ultimo-evento">
                      Último evento
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      {/* Componente Calendário */}
                      <div className="conteudo-calendario-filtro-simcard">
                        <Form.Control
                          name="ULTIMA_CONEXAO_INICIO"
                          type="text"
                          value={
                            filtroUltimaConexaoDataInicio
                              ? moment(filtroUltimaConexaoDataInicio).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : filtroUltimaConexaoDataInicio
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="De:"
                          data={filtroUltimaConexaoDataInicio}
                          setData={setFiltroUltimaConexaoDataInicio}
                          dataMax={
                            filtroUltimaConexaoDataFinal
                              ? filtroUltimaConexaoDataFinal
                              : dataMax
                          }
                          dataMin={dataMin}
                          rota="filtroSimcard-c"
                        />
                      </div>
                      <div className="conteudo-calendario-filtro-simcard">
                        <Form.Control
                          name="ULTIMA_CONEXAO_FINAL"
                          type="text"
                          value={
                            filtroUltimaConexaoDataFinal
                              ? moment(filtroUltimaConexaoDataFinal).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : filtroUltimaConexaoDataFinal
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="Até:"
                          data={filtroUltimaConexaoDataFinal}
                          setData={setFiltroUltimaConexaoDataFinal}
                          dataMax={dataMax}
                          dataMin={
                            filtroUltimaConexaoDataInicio
                              ? filtroUltimaConexaoDataInicio
                              : dataMin
                          }
                          rota="filtroSimcard-c"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-filtro-plano-tipo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-plano-tipo">
                      Plano tipo
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-plano-b fonte-cor-16">
                          Individual
                          {filtros.PLANO_TIPO == 'Individual' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'PLANO_TIPO',
                                'Individual'
                              )}
                              type="radio"
                              id="PLANO_TIPO_INDIVIDUAL"
                              name="PLANO_TIPO"
                              value="Individual"
                              onChange={() => {
                                setFiltroPlanoTipo('Individual')
                                setCheckAutomPlano(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'PLANO_TIPO',
                                'Individual'
                              )}
                              type="radio"
                              id="PLANO_TIPO_INDIVIDUAL"
                              name="PLANO_TIPO"
                              value="Individual"
                              onChange={() => {
                                setFiltroPlanoTipo('Individual')
                                setCheckAutomPlano(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-plano fonte-cor-16">
                          Compartilhado
                          {filtros.PLANO_TIPO == 'Compartilhado' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'PLANO_TIPO',
                                'Compartilhado'
                              )}
                              type="radio"
                              id="PLANO_TIPO_COMPARTILHADO"
                              name="PLANO_TIPO"
                              value="Compartilhado"
                              onChange={() => {
                                setFiltroPlanoTipo('Compartilhado')
                                setCheckAutomPlano(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'PLANO_TIPO',
                                'Compartilhado'
                              )}
                              type="radio"
                              id="PLANO_TIPO_COMPARTILHADO"
                              name="PLANO_TIPO"
                              value="Compartilhado"
                              onChange={() => {
                                setFiltroPlanoTipo('Compartilhado')
                                setCheckAutomPlano(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-plano-b fonte-cor-16">
                          Todos
                          {checkAutomPlano && !filtros.PLANO_TIPO ? (
                            <input
                              type="radio"
                              id="PLANO_TIPO_COMPARTILHADO"
                              name="PLANO_TIPO"
                              checked
                            />
                          ) : checkAutomPlano && !filtros.PLANO_TIPO ? (
                            <input
                              type="radio"
                              id="PLANO_TIPO_COMPARTILHADO"
                              name="PLANO_TIPO"
                            />
                          ) : (
                            <input
                              type="radio"
                              id="PLANO_TIPO_COMPARTILHADO"
                              name="PLANO_TIPO"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  {codigoClienteErp != 2455 && (
                    <div className="coluna-alterar-ciclo-vida-1-sms">
                      <Form.Label className="fonte-cor-1 label-campo-filtro campo-envio-sms">
                        Envio SMS
                      </Form.Label>
                      <div className="break-3" />
                      <Form.Group>
                        <div className="flex-filtros-conexao">
                          <label className="label-radio-filtros-sms-2 fonte-cor-16">
                            Liberado
                            {filtros.SMS_ATIVADO == '1' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '1'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="1"
                                onChange={() => {
                                  setFiltroEnvioSms('Liberado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '1'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="1"
                                onChange={() => {
                                  setFiltroEnvioSms('Liberado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-sms fonte-cor-16">
                            Bloqueado
                            {filtros.SMS_ATIVADO == '0' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '0'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="0"
                                onChange={() => {
                                  setFiltroEnvioSms('Bloqueado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '0'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="0"
                                onChange={() => {
                                  setFiltroEnvioSms('Bloqueado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <div className="flex-filtros-conexao">
                          <label className="label-radio-filtros-sms-2 fonte-cor-16">
                            Todos
                            {checkAutomSMS && !filtros.SMS_ATIVADO ? (
                              <input type="radio" name="SMS_ATIVADO" checked />
                            ) : checkAutomSMS && filtros.SMS_ATIVADO ? (
                              <input type="radio" name="SMS_ATIVADO" />
                            ) : (
                              <input type="radio" name="SMS_ATIVADO" />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-simcard">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-tipo-simcard">
                      Tipo de SIM Card
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <di className="flex-filtros">
                        <label
                          className="label-radio-filtros-sms-b fonte-cor-16"
                          onChange={() => {
                            setFiltroTipoSimcard('Normal')
                            setCheckAutomSIMCard(false)
                          }}
                        >
                          Normal
                          {filtros.TIPO_SIMCARD == 'Normal' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Normal'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_NORMAL"
                              name="TIPO_SIMCARD"
                              value="Normal"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Normal'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_NORMAL"
                              name="TIPO_SIMCARD"
                              value="Normal"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label
                          className="label-radio-filtros-sms-b fonte-cor-16"
                          onChange={() => {
                            setFiltroTipoSimcard('Triplo corte')
                            setCheckAutomSIMCard(false)
                          }}
                        >
                          Triplo corte
                          {filtros.TIPO_SIMCARD == 'Triplo corte' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Triplo corte'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Triplo_corte"
                              name="TIPO_SIMCARD"
                              value="Triplo corte"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Triplo corte'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Triplo_corte"
                              name="TIPO_SIMCARD"
                              value="Triplo corte"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </di>
                      <di className="flex-filtros">
                        <label
                          className="label-radio-filtros-sms-b fonte-cor-16"
                          onChange={() => {
                            setFiltroTipoSimcard('Micro')
                            setCheckAutomSIMCard(false)
                          }}
                        >
                          Micro
                          {filtros.TIPO_SIMCARD == 'Micro' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Micro'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Micro"
                              name="TIPO_SIMCARD"
                              value="Micro"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Micro'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Micro"
                              name="TIPO_SIMCARD"
                              value="Micro"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label
                          className="label-radio-filtros-sms-b fonte-cor-16"
                          onChange={() => {
                            setFiltroTipoSimcard('Nano')
                            setCheckAutomSIMCard(false)
                          }}
                        >
                          Nano
                          {filtros.TIPO_SIMCARD == 'Nano' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Nano'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Nano"
                              name="TIPO_SIMCARD"
                              value="Nano"
                              checked
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TIPO_SIMCARD',
                                'Nano'
                              )}
                              type="radio"
                              id="TIPO_SIMCARD_Nano"
                              name="TIPO_SIMCARD"
                              value="Nano"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </di>
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-sms-b fonte-cor-16">
                          Todos
                          {checkAutomSIMCard && !filtros.TIPO_SIMCARD ? (
                            <input type="radio" name="TIPO_SIMCARD" checked />
                          ) : checkAutomSIMCard && filtros.TIPO_SIMCARD ? (
                            <input type="radio" name="TIPO_SIMCARD" />
                          ) : (
                            <input type="radio" name="TIPO_SIMCARD" />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-pacote">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-pacote-extra">
                      Pacote extra
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      {codigoClienteErp != 2455 && (
                        <>
                          <div className="flex-filtros">
                            <label className="label-radio-filtros-extra fonte-cor-16">
                              Com
                              {filtros.PACOTE_EXTRA == 'Com' ? (
                                <input
                                  defaultChecked={handlerCheck(
                                    'PACOTE_EXTRA',
                                    'Com'
                                  )}
                                  type="radio"
                                  name="PACOTE_EXTRA"
                                  value="Com"
                                  onChange={() => {
                                    setFiltroPacoteExtra('Com')
                                    setCheckAutomPacote(false)
                                  }}
                                />
                              ) : (
                                <input
                                  defaultChecked={handlerCheck(
                                    'PACOTE_EXTRA',
                                    'Com'
                                  )}
                                  type="radio"
                                  name="PACOTE_EXTRA"
                                  value="Com"
                                  onChange={() => {
                                    setFiltroPacoteExtra('Com')
                                    setCheckAutomPacote(false)
                                  }}
                                />
                              )}
                              <span className="checkbox-filtros-b campo-texto-cor-3" />
                            </label>
                            <label className="label-radio-filtros-extra-b fonte-cor-16">
                              Sem
                              {filtros.PACOTE_EXTRA == 'Sem' ? (
                                <input
                                  defaultChecked={handlerCheck(
                                    'PACOTE_EXTRA',
                                    'Sem'
                                  )}
                                  type="radio"
                                  name="PACOTE_EXTRA"
                                  value="Sem"
                                  onChange={() => {
                                    setFiltroPacoteExtra('Sem')
                                    setCheckAutomPacote(false)
                                  }}
                                />
                              ) : (
                                <input
                                  defaultChecked={handlerCheck(
                                    'PACOTE_EXTRA',
                                    'Sem'
                                  )}
                                  type="radio"
                                  name="PACOTE_EXTRA"
                                  value="Sem"
                                  onChange={() => {
                                    setFiltroPacoteExtra('Sem')
                                    setCheckAutomPacote(false)
                                  }}
                                />
                              )}
                              <span className="checkbox-filtros-b campo-texto-cor-3" />
                            </label>
                          </div>
                          <div className="flex-filtros">
                            <label className="label-radio-filtros-extra-c fonte-cor-16">
                              Todos
                              {checkAutomPacote && !filtros.PACOTE_EXTRA ? (
                                <input
                                  type="radio"
                                  name="PACOTE_EXTRA"
                                  checked
                                />
                              ) : checkAutomPacote && filtros.PACOTE_EXTRA ? (
                                <input type="radio" name="PACOTE_EXTRA" />
                              ) : (
                                <input type="radio" name="PACOTE_EXTRA" />
                              )}
                              <span className="checkbox-filtros-b campo-texto-cor-3" />
                            </label>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-tempo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Pacotes{' '}
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-conexao">
                        <label className="label-radio-filtros-tempo fonte-cor-16">
                          No dia
                          {filtros.PACOTES == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'dia')}
                              type="radio"
                              name="PACOTES"
                              value="dia"
                              onChange={() => setFiltroPacotes('dia')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'dia')}
                              type="radio"
                              name="PACOTES"
                              value="dia"
                              onChange={() => setFiltroPacotes('dia')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-tempo-b fonte-cor-16">
                          No ciclo
                          {filtros.PACOTES == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'ciclo')}
                              type="radio"
                              name="PACOTES"
                              value="ciclo"
                              onChange={() => setFiltroPacotes('ciclo')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'ciclo')}
                              type="radio"
                              name="PACOTES"
                              value="ciclo"
                              onChange={() => setFiltroPacotes('ciclo')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin-pacotes">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="PACOTES_MIN"
                              className="filtros-texto-valor-mb-filtro-tempo campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirPacotes ? filtroPacotesMinimo : ''}
                              onChange={
                                permitirPacotes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-pacotes-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="PACOTES_MAX"
                              className="filtros-texto-valor-mb-filtro-tempo-2 campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirPacotes ? filtroPacotesMaximo : ''}
                              onChange={
                                permitirPacotes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-pacotes-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        {avisoPacotes && (
                          <Form.Label className="label-campo-consumo-invalido">
                            Máx. deve ser maior que Min.
                          </Form.Label>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                </div>
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards fundo-cor-14">
                  <div className="coluna-alterar-ciclo-vida-1-filtro-2">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Valor
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="filtros-texto-linha-margin">
                        <div className="col-sm">
                          <div className="d-flex">
                            {filtroValorMinimo != '' &&
                            filtroValorMinimo != undefined &&
                            filtroValorMinimo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao campo-texto-cor-3"
                                type="text"
                                value="R$"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="VALOR_MIN"
                              className={
                                filtroValorMinimo != '' &&
                                filtroValorMinimo != undefined &&
                                filtroValorMinimo != 'undefined'
                                  ? 'filtros-iccid-valor-min-b campo-texto-cor-3'
                                  : 'filtros-iccid-valor-min campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirValorMinimo ? filtroValorMinimo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-valor-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3 break-3" />
                          </div>
                        </div>
                        <div className="col-sm">
                          <label className="label-texto-valor-filtros fonte-cor-1">
                            {' '}
                          </label>
                          <div className="d-flex">
                            {filtroValorMaximo != '' &&
                            filtroValorMaximo != undefined &&
                            filtroValorMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao-b campo-texto-cor-3"
                                type="text"
                                value="R$"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="VALOR_MAX"
                              className={
                                filtroValorMaximo != '' &&
                                filtroValorMaximo != undefined &&
                                filtroValorMaximo != 'undefined'
                                  ? 'filtros-iccid-valor-max-b campo-texto-cor-3'
                                  : 'filtros-iccid-valor-max-2 campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirValorMaximo ? filtroValorMaximo : ''
                              }
                              // value={permitirValorMaximo ? filtroValorMaximo : "" }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-valor-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoValor && (
                            <Form.Label className="label-campo-valor-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-dias-2">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-dias-utilizados">
                      Dias utilizados
                    </Form.Label>
                    <div className="break-3" />
                    <div className="break-3" />
                    <Form.Group>
                      {filtroDiasUtilizadosMinimo != '' &&
                      filtroDiasUtilizadosMinimo != undefined &&
                      filtroDiasUtilizadosMinimo != 'undefined' ? (
                        <Form.Control
                          name="CIFRAO_VALOR_MIN"
                          className="filtros-dias campo-texto-cor-3"
                          type="text"
                          value="dias"
                          placeholder="Min."
                          autoComplete="off"
                        />
                      ) : (
                        ''
                      )}
                      <div className="filtros-texto-linha-margin">
                        <div className="col-sm">
                          <div className="d-flex">
                            <Form.Control
                              name="DIAS_USADOS_MIN"
                              className={
                                filtroDiasUtilizadosMinimo != '' &&
                                filtroDiasUtilizadosMinimo != undefined &&
                                filtroDiasUtilizadosMinimo != 'undefined'
                                  ? 'filtros-iccid-valor-min-2 campo-texto-cor-3'
                                  : 'filtros-iccid-valor-min-2-b campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirDiasMinimo
                                  ? filtroDiasUtilizadosMinimo
                                  : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-dias-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3 break-3" />
                          </div>
                        </div>
                        <div className="col-sm">
                          <label className="label-texto-valor-filtros fonte-cor-1">
                            {' '}
                          </label>
                          <div className="d-flex">
                            {filtroDiasUtilizadosMaximo != '' &&
                            filtroDiasUtilizadosMaximo != undefined &&
                            filtroDiasUtilizadosMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-dias-b campo-texto-cor-3"
                                type="text"
                                value="dias"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="DIAS_USADOS_MAX"
                              className={
                                filtroDiasUtilizadosMaximo != '' &&
                                filtroDiasUtilizadosMaximo != undefined &&
                                filtroDiasUtilizadosMaximo != 'undefined'
                                  ? 'filtros-iccid-valor-max-2-b campo-texto-cor-3'
                                  : 'filtros-iccid-valor-max campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirDiasrMaximo
                                  ? filtroDiasUtilizadosMaximo
                                  : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-dias-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoDias && (
                            <Form.Label className="label-campo-dias-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-dias-2">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-aquisicao">
                      Aquisição
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      {/* Componente Calendário */}
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="AQUISICAO_INICIO"
                          type="text"
                          value={
                            filtroAquisicaoDataInicio
                              ? moment(filtroAquisicaoDataInicio).format(
                                  'YYYY-MM-DD'
                                )
                              : filtroAquisicaoDataInicio
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="De:"
                          data={filtroAquisicaoDataInicio}
                          setData={setFiltroAquisicaoDataInicio}
                          dataMax={
                            filtroAquisicaoDataFinal
                              ? filtroAquisicaoDataFinal
                              : dataMax
                          }
                          dataMin={dataMin}
                          rota="filtroSimcard-b"
                        />
                      </div>
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="AQUISICAO_FINAL"
                          type="text"
                          value={
                            filtroAquisicaoDataFinal
                              ? moment(filtroAquisicaoDataFinal).format(
                                  'YYYY-MM-DD'
                                )
                              : filtroAquisicaoDataFinal
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="Até:"
                          data={filtroAquisicaoDataFinal}
                          setData={setFiltroAquisicaoDataFinal}
                          dataMax={dataMax}
                          dataMin={
                            filtroAquisicaoDataInicio
                              ? filtroAquisicaoDataInicio
                              : dataMin
                          }
                          rota="filtroSimcard-b"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-ativacao">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-ativacao">
                      Ativação
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      {/* Componente Calendário */}
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="ATIVACAO_INICIO"
                          type="text"
                          value={
                            filtroAtivacaoDataInicio
                              ? moment(filtroAtivacaoDataInicio).format(
                                  'YYYY-MM-DD hh:mm:ss'
                                )
                              : filtroAtivacaoDataInicio
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="De:"
                          data={filtroAtivacaoDataInicio}
                          setData={setFiltroAtivacaoDataInicio}
                          dataMax={
                            filtroAtivacaoDataFinal
                              ? filtroAtivacaoDataFinal
                              : dataMax
                          }
                          dataMin={dataMin}
                          rota="filtroSimcard"
                        />
                      </div>
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="ATIVACAO_FINAL"
                          type="text"
                          value={
                            filtroAtivacaoDataFinal
                              ? moment(filtroAtivacaoDataFinal).format(
                                  'YYYY-MM-DD hh:mm:ss'
                                )
                              : filtroAtivacaoDataFinal
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="Até:"
                          data={filtroAtivacaoDataFinal}
                          setData={setFiltroAtivacaoDataFinal}
                          dataMax={dataMax}
                          dataMin={
                            filtroAtivacaoDataInicio
                              ? filtroAtivacaoDataInicio
                              : dataMin
                          }
                          rota="filtroSimcard"
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="select-autocomplete-servico-adicional">
                    <Form.Group>
                      <Form.Label className="fonte-cor-1 label-campo-filtro label-servicos-adicionais">
                        Serviços Adicionais
                      </Form.Label>
                      <div className="break-3" />
                      <Form.Control
                        name="SERVICO_ADICIONAL"
                        type="text"
                        value={dsServicoAdicional}
                        className="d-none"
                        onChange={(e) => setFiltroMarca(e.target.value)}
                      />
                      {!revelarSelectServicoAdicional && (
                        <Button
                          className="campo-texto-cor-3 campo-select"
                          onClick={() =>
                            setRevelarSelectServicoAdicional(
                              !revelarSelectServicoAdicional
                            )
                          }
                        >
                          <div className="fonte-cor-1 label-campo">
                            {dsServicoAdicional}
                          </div>
                          <img
                            className={
                              revelarSelectServicoAdicional
                                ? 'campo-select-icone-ativado nao-selecionavel'
                                : 'campo-select-icone-desativado nao-selecionavel'
                            }
                            src={setaDropdown()}
                            alt="drop"
                          />
                        </Button>
                      )}

                      {revelarSelectServicoAdicional && (
                        <SelectGenerico
                          paramRota="dsp_grupo_produto"
                          paramCampo1={null}
                          paramCampo2={null}
                          paramCampo3={null}
                          paramCampo4={null}
                          setCodigo={setCdServicoAdicional}
                          setDescricao1={setDsServicoAdicional}
                          setDescricao2={setValorPacote}
                          setDescricao3={setDummy}
                          setDescricao4={setDummy}
                          setRevelarSelect={setRevelarSelectServicoAdicional}
                          placeholder={dsServicoAdicional}
                          sufixo=""
                          tipo="selectNormal"
                        />
                      )}
                    </Form.Group>
                  </div>

                  <div className="coluna-alterar-ciclo-vida-1-dias-3">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-consumo-2">
                      Consumo
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-consumo">
                        <label className="label-radio-filtros-pacote fonte-cor-16">
                          No dia
                          {filtros.CONSUMO == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'dia')}
                              type="radio"
                              name="CONSUMO"
                              value="dia"
                              onChange={() => setFiltroConsumo('dia')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'dia')}
                              type="radio"
                              name="CONSUMO"
                              value="dia"
                              onChange={() => setFiltroConsumo('dia')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-pacote-b fonte-cor-16">
                          No ciclo
                          {filtros.CONSUMO == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'ciclo')}
                              type="radio"
                              name="CONSUMO"
                              value="ciclo"
                              onChange={() => setFiltroConsumo('ciclo')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'ciclo')}
                              type="radio"
                              name="CONSUMO"
                              value="ciclo"
                              onChange={() => setFiltroConsumo('ciclo')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin-2">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroConsumoMinimo != '' &&
                            filtroConsumoMinimo != undefined &&
                            filtroConsumoMinimo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-mb campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="CONSUMO_MIN"
                              className="filtros-texto-valor-mb-filtro-pacote campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirConsumo ? filtroConsumoMinimo : ''}
                              onChange={
                                permitirConsumo
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-consumo-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroConsumoMaximo != '' &&
                            filtroConsumoMaximo != undefined &&
                            filtroConsumoMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-mb-b campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="CONSUMO_MAX"
                              className="filtros-texto-valor-mb-filtro-pacote-2 campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirConsumo ? filtroConsumoMaximo : ''}
                              onChange={
                                permitirConsumo
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-consumo-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoConsumo && (
                            <Form.Label className="label-campo-consumo-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards fundo-cor-14">
                  <div className="coluna-alterar-ciclo-vida-1-filtro">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Saldo
                    </Form.Label>
                    <div className="break-4" />
                    <div className="break-4" />
                    <Form.Group>
                      <div className="row filtros-texto-linha-margin">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroSaldoMinimo != '' &&
                            filtroSaldoMinimo != undefined &&
                            filtroSaldoMinimo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao-saldo campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="SALDO_MIN"
                              className={
                                filtroSaldoMinimo != '' &&
                                filtroSaldoMinimo != undefined &&
                                filtroSaldoMinimo != 'undefined'
                                  ? 'filtros-texto-valor-mb-filtro-saldo-min-b campo-texto-cor-3'
                                  : 'filtros-texto-valor-mb-filtro-saldo-min campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirSaldoMinimo ? filtroSaldoMinimo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-saldo-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroSaldoMaximo != '' &&
                            filtroSaldoMaximo != undefined &&
                            filtroSaldoMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao-saldo-b campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="SALDO_MAX"
                              className={
                                filtroSaldoMaximo != '' &&
                                filtroSaldoMaximo != undefined &&
                                filtroSaldoMaximo != 'undefined'
                                  ? 'filtros-texto-valor-mb-filtro-saldo-d campo-texto-cor-3'
                                  : 'filtros-texto-valor-mb-filtro-saldo-b campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirSaldoMaximo ? filtroSaldoMaximo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-saldo-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoSaldo && (
                            <Form.Label className="label-campo-saldo-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-filtro-iccid">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-iccid-intervalo">
                      ICCID por intervalo
                    </Form.Label>
                    <div className="break-4" />
                    <div className="break-4" />
                    <Form.Group>
                      <div className="row filtros-texto-linha-margin">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="RANGE_ICCID_MIN"
                              className="filtros-texto-valor-mb-filtro-iccid-c campo-texto-cor-3"
                              type="text"
                              maxLength="20"
                              value={
                                permitirIccidMinimo ? filtroIccidMinimo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-iccid-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="RANGE_ICCID_MAX"
                              className="filtros-texto-valor-mb-filtro-iccid campo-texto-cor-3"
                              type="text"
                              maxLength="20"
                              value={
                                permitirIccidMaximo ? filtroIccidMaximo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-iccid-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-modelo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro-2 campo-modelo">
                      Modelo
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="MODELO"
                      type="text"
                      value={
                        filtroModelo == 'Selecione' || resetModelo
                          ? ''
                          : filtroModelo
                      }
                      className="d-none"
                      onChange={(e) => setFiltroModelo(e.target.value)}
                    />
                    {revelarSelectModelo === false && (
                      <Button
                        disabled={
                          filtroModelo !== '' &&
                          filtroModelo !== null &&
                          filtroModelo !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-2"
                        onClick={() =>
                          setRevelarSelectModelo(!revelarSelectModelo)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {filtroModelo}
                        </div>
                        <img
                          className={
                            revelarSelectModelo
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectModelo === true && (
                      <SelectModelo
                        paramRota="dsp_imei_modelo"
                        setFiltroModelo={setFiltroModelo}
                        setRevelarSelectModelo={setRevelarSelectModelo}
                        setResetModelo={setResetModelo}
                        nomeModelo={filtroModelo}
                        rota="filtroSimcard"
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-marca">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-marca">
                      Marca
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="MARCA"
                      type="text"
                      value={
                        filtroMarca == 'Selecione' || resetMarca
                          ? ''
                          : filtroMarca
                      }
                      className="d-none"
                      onChange={(e) => setFiltroMarca(e.target.value)}
                    />
                    {revelarSelectMarca === false && (
                      <Button
                        disabled={
                          filtroMarca !== '' &&
                          filtroMarca !== null &&
                          filtroMarca !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-6"
                        onClick={() =>
                          setRevelarSelectMarca(!revelarSelectMarca)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {filtroMarca}
                        </div>
                        <img
                          className={
                            revelarSelectMarca
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectMarca === true && (
                      <SelectMarca
                        paramRota="dsp_imei_marca"
                        setFiltroMarca={setFiltroMarca}
                        setRevelarSelectMarca={setRevelarSelectMarca}
                        setResetMarca={setResetMarca}
                        nomeMarca={filtroMarca}
                        rota="filtroSimcard"
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-grupo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-filtro-grupo">
                      Grupo
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="GRUPO"
                      type="text"
                      value={
                        filtroGrupo == 'Selecione' || resetGrupo
                          ? ''
                          : filtroGrupo
                      }
                      className="d-none"
                      onChange={(e) => setCdGrupo(e.target.value)}
                    />
                    {revelarSelectGrupo === false && (
                      <Button
                        disabled={
                          revelarSelectGrupo !== '' &&
                          revelarSelectGrupo !== null &&
                          revelarSelectGrupo !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-5"
                        onClick={() =>
                          setRevelarSelectGrupo(!revelarSelectGrupo)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {resetGrupo ? 'Selecione' : filtroGrupo.split('*')[0]}
                        </div>
                        <img
                          className={
                            revelarSelectGrupo
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectGrupo === true && (
                      <SelectGrupo
                        rota="filtroSimcard"
                        setRevelarSelectGrupo={setRevelarSelectGrupo}
                        setFiltroGrupo={setFiltroGrupo}
                        setCdGrupo={setCdGrupo}
                        setResetGrupo={setResetGrupo}
                        nomeGrupo={filtroGrupo}
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-cliente-final">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-cliente-final">
                      Cliente Final
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Group>
                      <Form.Control
                        style={{ width: '100%' }}
                        name="CLIENTE_FINAL_CODIGO"
                        className="filtros-cliente-final campo-texto-cor-3"
                        type="text"
                        maxLength="20"
                        value={filtroClienteFinal}
                        onChange={(e) =>
                          validarNumero(
                            'campo-texto-cliente-final',
                            e.target.value
                          )
                        }
                        placeholder="Código do cliente"
                        autoComplete="off"
                      />
                      <Form.Control
                        style={{ width: '100%', marginTop: '10px' }}
                        name="CLIENTE_FINAL_NOME"
                        className="filtros-cliente-final campo-texto-cor-3"
                        type="text"
                        value={filtroNomeClienteFinal}
                        onChange={(e) => handleClienteFinalNome(e.target.value)}
                        placeholder="Nome do cliente"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Form.Row>
          ) : (
            //SE O NIVEL DO CLIENTE FOR IGUAL OU MAIOR QUE 3 - É PORTAL CLIENTE.
            //MOSTRA O FILTRO DE N3
            <Form.Row class="filtro-conteudo-container-portal">
              <Col>
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards fundo-cor-14">
                  <ListCheckboxOperadora
                    {...{
                      renderOperadora,
                      renderOperadoraParte2Verificar,
                      renderOperadoraParte2
                    }}
                  />

                  {/* ------------------- */}
                  {renderFormatarColunas ? (
                    <div className="coluna-alterar-ciclo-vida-1-status-b">
                      <Form.Label className="fonte-cor-1 label-campo-filtro">
                        Status{' '}
                      </Form.Label>
                      <div className="break-2" />
                      <Form.Group>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('online')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Online
                            {filtros.ULTIMA_CONEXAO == 'online' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-status-b fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('estoque')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Estoque
                            {filtros.ULTIMA_CONEXAO == 'estoque' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <did className="flex-filtros">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('ultima_conexao')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Offline
                            {filtros.ULTIMA_CONEXAO == 'ultima_conexao' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-status-b fonte-cor-16">
                            Todos
                            {checkAutomStatus && !filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                                checked
                              />
                            ) : checkAutomStatus && filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </did>
                      </Form.Group>
                    </div>
                  ) : (
                    <div
                      style={
                        renderOperadora.length < 4 ? { marginLeft: '0' } : {}
                      }
                      className="coluna-alterar-ciclo-vida-1-status"
                    >
                      <Form.Label className="fonte-cor-1 label-campo-filtro">
                        Status
                      </Form.Label>
                      <div className="break-2" />
                      <Form.Group>
                        <div className="flex-filtros-status">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('online')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Online
                            {filtros.ULTIMA_CONEXAO == 'online' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'online'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="online"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-status-b fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('estoque')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Estoque
                            {filtros.ULTIMA_CONEXAO == 'estoque' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'estoque'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="estoque"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <did className="flex-filtros-status">
                          <label
                            className="label-radio-filtros-status fonte-cor-16"
                            onChange={() => {
                              setFiltroUltimaConexao('ultima_conexao')
                              setCheckAutomStatus(false)
                            }}
                          >
                            Offline
                            {filtros.ULTIMA_CONEXAO == 'ultima_conexao' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'ULTIMA_CONEXAO',
                                  'ultima_conexao'
                                )}
                                type="radio"
                                name="ULTIMA_CONEXAO"
                                value="ultima_conexao"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-status-b fonte-cor-16">
                            Todos
                            {checkAutomStatus && !filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                                checked
                              />
                            ) : checkAutomStatus && filtros.ULTIMA_CONEXAO ? (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="ULTIMA_CONEXAO_TODOS"
                                name="ULTIMA_CONEXAO"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </did>
                      </Form.Group>
                    </div>
                  )}
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-tec">
                    <Form.Label>
                      <div className="fonte-cor-1 label-campo-filtro">
                        Tecnologia
                      </div>
                    </Form.Label>
                    <div className="break-1" />
                    <Form.Group>
                      <div className="flex-filtros">
                        <label
                          className="label-radio-filtros-2 fonte-cor-16"
                          onChange={() => {
                            setFiltroTecnologia('2G')
                            setCheckAutomTecnologia(false)
                          }}
                        >
                          2G
                          {filtros.TECNOLOGIA == '2G' ? (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '2G')}
                              type="radio"
                              id="TECNOLOGIA_2G"
                              name="TECNOLOGIA"
                              value="2G"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '2G')}
                              type="radio"
                              id="TECNOLOGIA_2G"
                              name="TECNOLOGIA"
                              value="2G"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label
                          className="label-radio-filtros-tec fonte-cor-16"
                          onChange={() => {
                            setFiltroTecnologia('3G')
                            setCheckAutomTecnologia(false)
                          }}
                        >
                          3G
                          {filtros.TECNOLOGIA == '3G' ? (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '3G')}
                              type="radio"
                              id="TECNOLOGIA_3G"
                              name="TECNOLOGIA"
                              value="3G"
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TECNOLOGIA', '3G')}
                              type="radio"
                              id="TECNOLOGIA_3G"
                              name="TECNOLOGIA"
                              value="3G"
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-2 fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('4G')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            4G
                            {filtros.TECNOLOGIA == '4G' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  '4G'
                                )}
                                type="radio"
                                id="TECNOLOGIA_4G"
                                name="TECNOLOGIA"
                                value="4G"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  '4G'
                                )}
                                type="radio"
                                id="TECNOLOGIA_4G"
                                name="TECNOLOGIA"
                                value="4G"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label
                            className="label-radio-filtros-tec fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('NB')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            NB-IoT
                            {filtros.TECNOLOGIA == 'NB' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'NB'
                                )}
                                type="radio"
                                id="TECNOLOGIA_NB"
                                name="TECNOLOGIA"
                                value="NB"
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'NB'
                                )}
                                type="radio"
                                id="TECNOLOGIA_NB"
                                name="TECNOLOGIA"
                                value="NB"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <div className="flex-filtros">
                          <label
                            className="label-radio-filtros-2 fonte-cor-16"
                            onChange={() => {
                              setFiltroTecnologia('Outras')
                              setCheckAutomTecnologia(false)
                            }}
                          >
                            Outras
                            {filtros.TECNOLOGIA == 'Outras' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'OUTRAS'
                                )}
                                type="radio"
                                id="TECNOLOGIA_OUTRAS"
                                name="TECNOLOGIA"
                                value="OUTRAS"
                                checked
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'TECNOLOGIA',
                                  'OUTRAS'
                                )}
                                type="radio"
                                id="TECNOLOGIA_OUTRAS"
                                name="TECNOLOGIA"
                                value="OUTRAS"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-tec fonte-cor-16">
                            Todos
                            {checkAutomTecnologia && !filtros.TECNOLOGIA ? (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                                checked
                              />
                            ) : checkAutomTecnologia && filtros.TECNOLOGIA ? (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                              />
                            ) : (
                              <input
                                type="radio"
                                id="TECNOLOGIA_TODOS"
                                name="TECNOLOGIA"
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-1-filtro">
                    <Form.Group>
                      <Form.Label className="fonte-cor-1 label-campo-filtro campo-trafego">
                        Tráfego{' '}
                      </Form.Label>
                      <div className="break-3" />
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-trafego-2 fonte-cor-16">
                          Ativo
                          {filtros.TRAFEGO == 'Ativo' ? (
                            <input
                              defaultChecked={handlerCheck('TRAFEGO', 'Ativo')}
                              type="radio"
                              name="TRAFEGO"
                              value="Ativo"
                              onChange={() => {
                                setFiltroTrafego('Ativo')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('TRAFEGO', 'Ativo')}
                              type="radio"
                              name="TRAFEGO"
                              value="Ativo"
                              onChange={() => {
                                setFiltroTrafego('Ativo')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-trafego fonte-cor-16">
                          Bloqueado
                          {filtros.TRAFEGO == 'Bloqueado' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Bloqueado'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Bloqueado"
                              onChange={() => {
                                setFiltroTrafego('Bloqueado')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Bloqueado'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Bloqueado"
                              onChange={() => {
                                setFiltroTrafego('Bloqueado')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="flex-filtros">
                        <label className="label-radio-filtros-trafego-2 fonte-cor-16">
                          Suspenso
                          {filtros.TRAFEGO == 'Suspenso' ? (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Suspenso'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Suspenso"
                              onChange={() => {
                                setFiltroTrafego('Suspenso')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck(
                                'TRAFEGO',
                                'Suspenso'
                              )}
                              type="radio"
                              name="TRAFEGO"
                              value="Suspenso"
                              onChange={() => {
                                setFiltroTrafego('Suspenso')
                                setCheckAutomTrafego(false)
                              }}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-trafego fonte-cor-16">
                          Todos
                          {checkAutomTrafego && !filtros.TRAFEGO ? (
                            <input type="radio" name="TRAFEGO" checked />
                          ) : checkAutomTrafego && filtros.TRAFEGO ? (
                            <input type="radio" name="TRAFEGO" />
                          ) : (
                            <input type="radio" name="TRAFEGO" />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  {/* ------------------- */}
                  <div className="coluna-alterar-ciclo-vida-conexao">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Conexões{' '}
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-conexao-container">
                        <label className="label-radio-filtros-conexao fonte-cor-16">
                          No dia
                          {filtros.CONEXOES == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'dia')}
                              type="radio"
                              name="CONEXOES"
                              value="dia"
                              onChange={() => {
                                setFiltroConexoes('dia')
                                setPermitirConexoes(true)
                              }}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'dia')}
                              type="radio"
                              name="CONEXOES"
                              value="dia"
                              onChange={() => {
                                setFiltroConexoes('dia')
                                setPermitirConexoes(true)
                              }}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-2 fonte-cor-16">
                          No ciclo
                          {filtros.CONEXOES == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'ciclo')}
                              type="radio"
                              name="CONEXOES"
                              value="ciclo"
                              onChange={() => setFiltroConexoes('ciclo')}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONEXOES', 'ciclo')}
                              type="radio"
                              name="CONEXOES"
                              value="ciclo"
                              onChange={() => setFiltroConexoes('ciclo')}
                              onClick={() => setPermitirConexoes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="CONEXOES_MIN"
                              className="filtros-texto-valor-mb-filtro-conexoes campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={
                                permitirConexoes ? filtroConexoesMinimo : ''
                              }
                              onChange={
                                permitirConexoes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-conexoes-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className=" fonte-cor-16 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="CONEXOES_MAX"
                              className="filtros-texto-valor-mb-filtro campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={
                                permitirConexoes ? filtroConexoesMaximo : ''
                              }
                              onChange={
                                permitirConexoes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-conexoes-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-16 campo-texto-cor-3" />
                          </div>
                        </div>
                        {avisoConexoes && (
                          <Form.Label className="label-campo-conexao-invalido">
                            Máx. deve ser maior que Min.
                          </Form.Label>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ------------------- */}
                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards fundo-cor-14">
                  <div className="coluna-alterar-ciclo-vida-1-dias-portal">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-ultimo-evento">
                      Último evento
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      {/* Componente Calendário */}
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="ULTIMA_CONEXAO_INICIO"
                          type="text"
                          value={
                            filtroUltimaConexaoDataInicio
                              ? moment(filtroUltimaConexaoDataInicio).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : filtroUltimaConexaoDataInicio
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="De:"
                          data={filtroUltimaConexaoDataInicio}
                          setData={setFiltroUltimaConexaoDataInicio}
                          dataMax={
                            filtroUltimaConexaoDataFinal
                              ? filtroUltimaConexaoDataFinal
                              : dataMax
                          }
                          dataMin={dataMin}
                          rota="filtroSimcard-c"
                        />
                      </div>
                      <div className="conteudo-calendario-filtro">
                        <Form.Control
                          name="ULTIMA_CONEXAO_FINAL"
                          type="text"
                          value={
                            filtroUltimaConexaoDataFinal
                              ? moment(filtroUltimaConexaoDataFinal).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : filtroUltimaConexaoDataFinal
                          }
                          className="d-none"
                        />
                        <Calendario
                          imputCustomisado="Até:"
                          data={filtroUltimaConexaoDataFinal}
                          setData={setFiltroUltimaConexaoDataFinal}
                          dataMax={dataMax}
                          dataMin={
                            filtroUltimaConexaoDataInicio
                              ? filtroUltimaConexaoDataInicio
                              : dataMin
                          }
                          rota="filtroSimcard-c"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  {/* ------------------- */}
                  {codigoClienteErp != 2455 && (
                    <div className="coluna-alterar-ciclo-vida-1-sms-portal">
                      <Form.Label className="fonte-cor-1 label-campo-filtro campo-envio-sms">
                        Envio SMS
                      </Form.Label>
                      <div className="break-3" />
                      <Form.Group>
                        <div className="flex-filtros-conexao">
                          <label className="label-radio-filtros-sms-2 fonte-cor-16">
                            Liberado
                            {filtros.SMS_ATIVADO == '1' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '1'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="1"
                                onChange={() => {
                                  setFiltroEnvioSms('Liberado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '1'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="1"
                                onChange={() => {
                                  setFiltroEnvioSms('Liberado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                          <label className="label-radio-filtros-sms fonte-cor-16">
                            Bloqueado
                            {filtros.SMS_ATIVADO == '0' ? (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '0'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="0"
                                onChange={() => {
                                  setFiltroEnvioSms('Bloqueado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            ) : (
                              <input
                                defaultChecked={handlerCheck(
                                  'SMS_ATIVADO',
                                  '0'
                                )}
                                type="radio"
                                name="SMS_ATIVADO"
                                value="0"
                                onChange={() => {
                                  setFiltroEnvioSms('Bloqueado')
                                  setCheckAutomSMS(false)
                                }}
                              />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                        <div className="flex-filtros-conexao">
                          <label className="label-radio-filtros-sms-2 fonte-cor-16">
                            Todos
                            {checkAutomSMS && !filtros.SMS_ATIVADO ? (
                              <input type="radio" name="SMS_ATIVADO" checked />
                            ) : checkAutomSMS && filtros.SMS_ATIVADO ? (
                              <input type="radio" name="SMS_ATIVADO" />
                            ) : (
                              <input type="radio" name="SMS_ATIVADO" />
                            )}
                            <span className="checkbox-filtros-b campo-texto-cor-3" />
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  {/* ------------------- */}
                  {/* <div className="coluna-alterar-ciclo-vida-1-pacote-portal">
                                        <Form.Label className="fonte-cor-1 label-campo-filtro campo-pacote-extra">Pacote extra</Form.Label>
                                        <div className="break-3"></div>
                                        <Form.Group>
                                            {codigoClienteErp != 2455 &&
                                                <>
                                                    <div className="flex-filtros">
                                                        <label className="label-radio-filtros-extra fonte-cor-16">Com
                                                        {filtros.PACOTE_EXTRA  == "Com" ? <input defaultChecked={handlerCheck("PACOTE_EXTRA", "Com")} type="radio" name="PACOTE_EXTRA" value="Com" onChange={() => {setFiltroPacoteExtra('Com'); setCheckAutomPacote(false)}}/> : <input defaultChecked={handlerCheck("PACOTE_EXTRA", "Com")} type="radio" name="PACOTE_EXTRA" value="Com" onChange={() => {setFiltroPacoteExtra('Com'); setCheckAutomPacote(false)}} />}
                                                            <span className="checkbox-filtros-b campo-texto-cor-3"></span>
                                                        </label>
                                                        <label className="label-radio-filtros-extra-b fonte-cor-16">Sem
                                                        {filtros.PACOTE_EXTRA  == "Sem" ?  <input defaultChecked={handlerCheck("PACOTE_EXTRA", "Sem")} type="radio" name="PACOTE_EXTRA" value="Sem" onChange={() => {setFiltroPacoteExtra('Sem'); setCheckAutomPacote(false)}}/> :   <input defaultChecked={handlerCheck("PACOTE_EXTRA", "Sem")} type="radio" name="PACOTE_EXTRA" value="Sem" onChange={() => {setFiltroPacoteExtra('Sem'); setCheckAutomPacote(false)}} />}
                                                            <span className="checkbox-filtros-b campo-texto-cor-3"></span>
                                                        </label>
                                                    </div>
                                                    <div className="flex-filtros">
                                                        <label className="label-radio-filtros-extra-c fonte-cor-16">Todos
                                                            {checkAutomPacote && !filtros.PACOTE_EXTRA ? <input type="radio" name="PACOTE_EXTRA" checked/>
                                                                : checkAutomPacote && filtros.PACOTE_EXTRA ?
                                                                    <input type="radio" name="PACOTE_EXTRA"/> :
                                                                    <input type="radio" name="PACOTE_EXTRA"/>}
                                                            <span className="checkbox-filtros-b campo-texto-cor-3"></span>
                                                        </label>
                                                    </div>
                                                </>
                                            }
                                        </Form.Group>
                                    </div> */}
                  {/* ------------------- */}

                  {isPortal && (
                    <div className="select-autocomplete-servico-adicional-portal">
                      <Form.Group>
                        <Form.Label className="fonte-cor-1 label-campo-filtro label-servicos-adicionais">
                          Serviços Adicionais
                        </Form.Label>
                        <div className="break-3" />
                        <Form.Control
                          name="SERVICO_ADICIONAL"
                          type="text"
                          value={dsServicoAdicional}
                          className="d-none"
                          onChange={(e) => setFiltroMarca(e.target.value)}
                        />
                        {!revelarSelectServicoAdicional && (
                          <Button
                            className="campo-texto-cor-3 campo-select"
                            onClick={() =>
                              setRevelarSelectServicoAdicional(
                                !revelarSelectServicoAdicional
                              )
                            }
                          >
                            <div className="fonte-cor-1 label-campo">
                              {dsServicoAdicional}
                            </div>
                            <img
                              className={
                                revelarSelectServicoAdicional
                                  ? 'campo-select-icone-ativado nao-selecionavel'
                                  : 'campo-select-icone-desativado nao-selecionavel'
                              }
                              src={setaDropdown()}
                              alt="drop"
                            />
                          </Button>
                        )}

                        {revelarSelectServicoAdicional && (
                          <SelectGenerico
                            paramRota="dsp_grupo_produto"
                            paramCampo1={null}
                            paramCampo2={null}
                            paramCampo3={null}
                            paramCampo4={null}
                            setCodigo={setCdServicoAdicional}
                            setDescricao1={setDsServicoAdicional}
                            setDescricao2={setValorPacote}
                            setDescricao3={setDummy}
                            setDescricao4={setDummy}
                            setRevelarSelect={setRevelarSelectServicoAdicional}
                            placeholder={dsServicoAdicional}
                            sufixo=""
                            tipo="selectNormal"
                          />
                        )}
                      </Form.Group>
                    </div>
                  )}

                  <div className="coluna-alterar-ciclo-vida-1-tempo-portal">
                    <Form.Label className="fonte-cor-1 label-campo-filtro label-pacotes-portal">
                      Pacotes
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-conexao">
                        <label className="label-radio-filtros-tempo fonte-cor-16">
                          No dia
                          {filtros.PACOTES == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'dia')}
                              type="radio"
                              name="PACOTES"
                              value="dia"
                              onChange={() => setFiltroPacotes('dia')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'dia')}
                              type="radio"
                              name="PACOTES"
                              value="dia"
                              onChange={() => setFiltroPacotes('dia')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-tempo-b fonte-cor-16">
                          No ciclo
                          {filtros.PACOTES == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'ciclo')}
                              type="radio"
                              name="PACOTES"
                              value="ciclo"
                              onChange={() => setFiltroPacotes('ciclo')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('PACOTES', 'ciclo')}
                              type="radio"
                              name="PACOTES"
                              value="ciclo"
                              onChange={() => setFiltroPacotes('ciclo')}
                              onClick={() => setPermitirPacotes(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin-pacotes">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="PACOTES_MIN"
                              className="filtros-texto-valor-mb-filtro-tempo campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirPacotes ? filtroPacotesMinimo : ''}
                              onChange={
                                permitirPacotes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-pacotes-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="PACOTES_MAX"
                              className="filtros-texto-valor-mb-filtro-tempo-2 campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirPacotes ? filtroPacotesMaximo : ''}
                              onChange={
                                permitirPacotes
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-pacotes-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        {avisoPacotes && (
                          <Form.Label className="label-campo-consumo-invalido">
                            Máx. deve ser maior que Min.
                          </Form.Label>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                </div>

                <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro-simcards fundo-cor-14">
                  <div className="coluna-alterar-ciclo-vida-1-filtro">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Saldo
                    </Form.Label>
                    <div className="break-4" />
                    <div className="break-4" />
                    <Form.Group>
                      <div className="row filtros-texto-linha-margin">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroSaldoMinimo != '' &&
                            filtroSaldoMinimo != undefined &&
                            filtroSaldoMinimo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao-saldo campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="SALDO_MIN"
                              className={
                                filtroSaldoMinimo != '' &&
                                filtroSaldoMinimo != undefined &&
                                filtroSaldoMinimo != 'undefined'
                                  ? 'filtros-texto-valor-mb-filtro-saldo-min-b campo-texto-cor-3'
                                  : 'filtros-texto-valor-mb-filtro-saldo-min campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirSaldoMinimo ? filtroSaldoMinimo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-saldo-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroSaldoMaximo != '' &&
                            filtroSaldoMaximo != undefined &&
                            filtroSaldoMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-cifrao-saldo-b campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="SALDO_MAX"
                              className={
                                filtroSaldoMaximo != '' &&
                                filtroSaldoMaximo != undefined &&
                                filtroSaldoMaximo != 'undefined'
                                  ? 'filtros-texto-valor-mb-filtro-saldo-d campo-texto-cor-3'
                                  : 'filtros-texto-valor-mb-filtro-saldo-b campo-texto-cor-3'
                              }
                              type="text"
                              maxLength="4"
                              value={
                                permitirSaldoMaximo ? filtroSaldoMaximo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-saldo-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoSaldo && (
                            <Form.Label className="label-campo-saldo-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-filtro-iccid">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-iccid-intervalo">
                      ICCID por intervalo
                    </Form.Label>
                    <div className="break-4" />
                    <div className="break-4" />
                    <Form.Group>
                      <div className="row filtros-texto-linha-margin">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="RANGE_ICCID_MIN"
                              className="filtros-texto-valor-mb-filtro-iccid-c campo-texto-cor-3"
                              type="text"
                              maxLength="20"
                              value={
                                permitirIccidMinimo ? filtroIccidMinimo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-iccid-minimo',
                                  e.target.value
                                )
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            <Form.Control
                              name="RANGE_ICCID_MAX"
                              className="filtros-texto-valor-mb-filtro-iccid campo-texto-cor-3"
                              type="text"
                              maxLength="20"
                              value={
                                permitirIccidMaximo ? filtroIccidMaximo : ''
                              }
                              onChange={(e) =>
                                validarNumero(
                                  'campo-texto-iccid-maximo',
                                  e.target.value
                                )
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-modelo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro-2 campo-modelo">
                      Modelo
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="MODELO"
                      type="text"
                      value={
                        filtroModelo == 'Selecione' || resetModelo
                          ? ''
                          : filtroModelo
                      }
                      className="d-none"
                      onChange={(e) => setFiltroModelo(e.target.value)}
                    />
                    {revelarSelectModelo === false && (
                      <Button
                        disabled={
                          filtroModelo !== '' &&
                          filtroModelo !== null &&
                          filtroModelo !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-2"
                        onClick={() =>
                          setRevelarSelectModelo(!revelarSelectModelo)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {filtroModelo}
                        </div>
                        <img
                          className={
                            revelarSelectModelo
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectModelo === true && (
                      <SelectModelo
                        paramRota="dsp_imei_modelo"
                        setFiltroModelo={setFiltroModelo}
                        setRevelarSelectModelo={setRevelarSelectModelo}
                        setResetModelo={setResetModelo}
                        nomeModelo={filtroModelo}
                        rota="filtroSimcard"
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-marca">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-marca">
                      Marca
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="MARCA"
                      type="text"
                      value={
                        filtroMarca == 'Selecione' || resetMarca
                          ? ''
                          : filtroMarca
                      }
                      className="d-none"
                      onChange={(e) => setFiltroMarca(e.target.value)}
                    />
                    {revelarSelectMarca === false && (
                      <Button
                        disabled={
                          filtroMarca !== '' &&
                          filtroMarca !== null &&
                          filtroMarca !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-6"
                        onClick={() =>
                          setRevelarSelectMarca(!revelarSelectMarca)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {filtroMarca}
                        </div>
                        <img
                          className={
                            revelarSelectMarca
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectMarca === true && (
                      <SelectMarca
                        paramRota="dsp_imei_marca"
                        setFiltroMarca={setFiltroMarca}
                        setRevelarSelectMarca={setRevelarSelectMarca}
                        setResetMarca={setResetMarca}
                        nomeMarca={filtroMarca}
                        rota="filtroSimcard"
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-status-grupo">
                    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-filtro-grupo">
                      Grupo
                    </Form.Label>
                    <div className="break-4" />
                    <Form.Control
                      name="GRUPO"
                      type="text"
                      value={
                        filtroGrupo == 'Selecione' || resetGrupo
                          ? ''
                          : filtroGrupo
                      }
                      className="d-none"
                      onChange={(e) => setCdGrupo(e.target.value)}
                    />
                    {revelarSelectGrupo === false && (
                      <Button
                        disabled={
                          revelarSelectGrupo !== '' &&
                          revelarSelectGrupo !== null &&
                          revelarSelectGrupo !== undefined
                            ? false
                            : true
                        }
                        className="campo-texto-cor-3 campo-select-filtro-5"
                        onClick={() =>
                          setRevelarSelectGrupo(!revelarSelectGrupo)
                        }
                      >
                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho-2">
                          {resetGrupo ? 'Selecione' : filtroGrupo.split('*')[0]}
                        </div>
                        <img
                          className={
                            revelarSelectGrupo
                              ? 'campo-select-icone-ativado nao-selecionavel'
                              : 'campo-select-icone-desativado-filtro-b nao-selecionavel'
                          }
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </Button>
                    )}
                    {revelarSelectGrupo === true && (
                      <SelectGrupo
                        rota="filtroSimcard"
                        setRevelarSelectGrupo={setRevelarSelectGrupo}
                        setFiltroGrupo={setFiltroGrupo}
                        setCdGrupo={setCdGrupo}
                        setResetGrupo={setResetGrupo}
                        nomeGrupo={filtroGrupo}
                      />
                    )}
                  </div>
                  <div className="coluna-alterar-ciclo-vida-1-consumo-portal">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-consumo-2">
                      Consumo
                    </Form.Label>
                    <div className="break-3" />
                    <Form.Group>
                      <div className="flex-filtros-consumo-portal">
                        <label className="label-radio-filtros-pacote fonte-cor-16">
                          No dia
                          {filtros.CONSUMO == 'dia' ? (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'dia')}
                              type="radio"
                              name="CONSUMO"
                              value="dia"
                              onChange={() => setFiltroConsumo('dia')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'dia')}
                              type="radio"
                              name="CONSUMO"
                              value="dia"
                              onChange={() => setFiltroConsumo('dia')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                        <label className="label-radio-filtros-pacote-b fonte-cor-16">
                          No ciclo
                          {filtros.CONSUMO == 'ciclo' ? (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'ciclo')}
                              type="radio"
                              name="CONSUMO"
                              value="ciclo"
                              onChange={() => setFiltroConsumo('ciclo')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          ) : (
                            <input
                              defaultChecked={handlerCheck('CONSUMO', 'ciclo')}
                              type="radio"
                              name="CONSUMO"
                              value="ciclo"
                              onChange={() => setFiltroConsumo('ciclo')}
                              onClick={() => setPermitirConsumo(true)}
                            />
                          )}
                          <span className="checkbox-filtros-b campo-texto-cor-3" />
                        </label>
                      </div>
                      <div className="row filtros-texto-linha-margin-portal">
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroConsumoMinimo != '' &&
                            filtroConsumoMinimo != undefined &&
                            filtroConsumoMinimo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-mb campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="CONSUMO_MIN"
                              className="filtros-texto-valor-mb-filtro-pacote-portal campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirConsumo ? filtroConsumoMinimo : ''}
                              onChange={
                                permitirConsumo
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-consumo-minimo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Min."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex">
                            {filtroConsumoMaximo != '' &&
                            filtroConsumoMaximo != undefined &&
                            filtroConsumoMaximo != 'undefined' ? (
                              <Form.Control
                                name="CIFRAO_VALOR_MIN"
                                className="filtros-mb-b campo-texto-cor-3"
                                type="text"
                                value="MB"
                                placeholder="Min."
                                autoComplete="off"
                              />
                            ) : (
                              ''
                            )}
                            <Form.Control
                              name="CONSUMO_MAX"
                              className="filtros-texto-valor-mb-filtro-pacote-2 campo-texto-cor-3"
                              type="text"
                              maxLength="4"
                              value={permitirConsumo ? filtroConsumoMaximo : ''}
                              onChange={
                                permitirConsumo
                                  ? (e) =>
                                      validarNumero(
                                        'campo-texto-consumo-maximo',
                                        e.target.value
                                      )
                                  : ''
                              }
                              placeholder="Máx."
                              autoComplete="off"
                            />
                            <label className="fonte-cor-1 campo-texto-cor-3" />
                          </div>
                          {avisoConsumo && (
                            <Form.Label className="label-campo-consumo-invalido">
                              Máx. deve ser maior que Min.
                            </Form.Label>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>

                  {/* ------------------- */}
                  {/*<div className="coluna-alterar-ciclo-vida-1-cliente-final">*/}
                  {/*    <Form.Label className="fonte-cor-1 label-campo-filtro status-contrato-filtro campo-cliente-final">Cliente Final</Form.Label>*/}
                  {/*    <div className="break-4"></div>*/}
                  {/*    <Form.Group>*/}
                  {/*        <Form.Control*/}
                  {/*            style={{ "width": "100%" }}*/}
                  {/*            name="CLIENTE_FINAL_CODIGO"*/}
                  {/*            className="filtros-cliente-final campo-texto-cor-3"*/}
                  {/*            type="text"*/}
                  {/*            maxLength="10"*/}
                  {/*            value={filtroClienteFinal}*/}
                  {/*            onChange={e => validarNumero("campo-texto-cliente-final", e.target.value)}*/}
                  {/*            placeholder='Código do cliente'*/}
                  {/*            autoComplete='off'*/}
                  {/*        />*/}
                  {/*    </Form.Group>*/}
                  {/*</div>*/}
                </div>
              </Col>
            </Form.Row>
          )}

          <Form.Row />
          <Form.Row>
            <Col>
              <div className="container-tabela-modal-faturas">
                {({ paginationTableProps }) => (
                  <>
                    <BootstrapTable
                      classes="tabela-transparente-modais"
                      condensed
                      keyField="CD_SIMCARD"
                      data={itens}
                      columns={colunas}
                      rowEvents={eventosLinhas}
                      noDataIndication="Nenhum item encontrado"
                      bootstrap4
                      {...paginationTableProps}
                    />
                  </>
                )}
              </div>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col />
          </Form.Row>
          <Form.Row />
        </div>

        {/* <div className="drop-acoes-lista-menu flex-filtros"> */}

        {/* ------------------- */}
        {/* <div className="drop-filtros-menu ">
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                    </div>
                    <div>
                        {renderOperadora}
                    </div> */}
        {/* ------------------- */}
        {/* <div className="drop-filtros-menu">
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tecnologia </Form.Label>
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTecnologia('2G')}>2G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","2G")} type="radio" id="TECNOLOGIA_2G" name="TECNOLOGIA" value="2G"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTecnologia('3G')}>3G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","3G")} type="radio" id="TECNOLOGIA_3G" name="TECNOLOGIA" value="3G"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTecnologia('NB')}>NB-IoT
                            <input defaultChecked={handlerCheck("TECNOLOGIA","NB")} type="radio" id="TECNOLOGIA_NB" name="TECNOLOGIA" value="NB"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTecnologia('4G')}>4G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","4G")} type="radio" id="TECNOLOGIA_4G" name="TECNOLOGIA" value="4G"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => setFiltroTecnologia('Outras')}>Outras
                            <input defaultChecked={handlerCheck("TECNOLOGIA","OUTRAS")} type="radio" id="TECNOLOGIA_OUTRAS" name="TECNOLOGIA" value="OUTRAS"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> */}
        {/* ------------------- */}

        <div className="botao-central-filtros">
          {/* <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row> */}
        </div>
      </div>
      {/* </div> */}
    </Form>
  )
}

function ListCheckboxOperadora({
  renderOperadora,
  renderOperadoraParte2Verificar,
  renderOperadoraParte2
}) {
  return (
    <div className="container-operadoras-filtro-simcard">
      <div className="coluna-alterar-ciclo-vida-1-operadora">
        <Form.Label className="fonte-cor-1 label-campo-filtro">
          Operadoras
        </Form.Label>
        <div className="break" />
        <>{renderOperadora}</>
      </div>
      <div className="operadoras-filtro">
        <>{renderOperadoraParte2Verificar && renderOperadoraParte2}</>
      </div>
    </div>
  )
}

function CheckboxOperadora({ operadora, defaultChecked, ...restProps }) {
  return (
    <label
      className="label-checkbox-filtros-operadoras-2 fonte-cor-16"
      {...restProps}
    >
      {operadora.ds_dsp_operadora}
      <input
        defaultChecked={defaultChecked}
        type="checkbox"
        name="OPERADORA"
        value={operadora.cd_dsp_operadora}
      />
      <span className="checkbox-filtros-operadoras-2" />
    </label>
  )
}

export default Filtros
