import React from 'react'
import { PortadorasService } from '../../../../services/http/portadoras.service.js'
import { useGlobal } from '../../../../stores/useGlobal.js'
import MapaGoogle from '../../../googleMap/googleMap'
import * as S from './styles'

import { useEffect } from 'react'

export default function Localizacao({ row, onUpdateLocation }) {
  const { getLocation } = PortadorasService
  const { setIsLoadingGlobal } = useGlobal()

  useEffect(() => {
    if (!row.latitude || !row.longitude) {
      if (row.latitude === '-') return

      setIsLoadingGlobal(true)
      getLocation(row.id)
        .then((response) => {
          const { lat, long } = response
          const location = {
            lat: lat || '-',
            long: long || '-'
          }

          onUpdateLocation({ ...location, id: row.id })
        })
        .finally(() => setIsLoadingGlobal(false))
    }
  }, [])

  if (!(row.latitude && row.longitude) || row.latitude === '-') {
    return (
      <S.SemLocalizacao>
        Não foram encontrados dados para a localização da antena
      </S.SemLocalizacao>
    )
  }

  return <MapaGoogle array={[row]} tipo={'localizacao'} />
}
