import React from "react";

import TabelaPortadoras from "../../components/Portadoras/Tabela";

export default function Portadoras() {
  return (
    <div>
      <TabelaPortadoras />
    </div>
  );
}
