//IMPORTAÇÕES
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'

import { api_svsolicitacoes } from '../../../../conexoes/api'
import { useGlobal } from '../../../../stores/useGlobal'
import { formatarData } from '../../../../utils/formatadorDataTabela'
import { formatarMilhar } from '../../../../utils/formatarMilhar'
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import { requestResolver } from '../../../../utils/requestResolver'

import * as S from './styles'

let totalPaginas = 1
let totalItens = 10
const valorLimite = 5

const Card = ({ titulo, descricao }) => {
  return (
    <S.Card>
      <S.Titulo>{titulo}</S.Titulo>
      <S.Descricao> {descricao || '-'}</S.Descricao>
    </S.Card>
  )
}

const fetchDetalhamento = async (cdSolicitacao) => {
  return await requestResolver(
    api_svsolicitacoes.get(
      `api/v2/detalhesSolConectividade?cd_crm_solicitacao=${cdSolicitacao}`
    )
  )
}
export default function DetalhamentoSolicitacao({
  cdSolicitacao,
  tipoSolicitacao
}) {
  const { setIsLoadingGlobal } = useGlobal()

  const [itens, setItens] = useState([])
  const [paginaAtual, setPaginaAtual] = useState(1)

  const isCancelamento = tipoSolicitacao === 'Cancelamento Simcard'

  useQuery(
    [`detalhesSolConectividade${cdSolicitacao}`, cdSolicitacao],
    async () => {
      try {
        setIsLoadingGlobal(true)
        return await fetchDetalhamento(cdSolicitacao)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        setItens(data.devices)
        totalPaginas = Math.ceil(data.devices.length / valorLimite)
        totalItens = data.devices.length
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  function parseTecnologia(cell) {
    if (!cell) {
      return 'Sem informação'
    } else {
      return cell
    }
  }

  const colunas = [
    {
      dataField: 'LINHA',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">MSISDN</div>
      ),
      sort: true,
      formatter: (cell) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'OPERADORA',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">Operadora</div>
      ),
      sort: true,
      formatter: (cell) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell == 'undefined' || cell == '' ? '-' : cell}
          </p>
        )
      }
    },
    {
      dataField: 'ICCID',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">ICCID</div>
      ),
      sort: true,
      formatter: (cell) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'TIPO_SIMCARD',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">
          Tipo de SIM card
        </div>
      ),
      sort: true,
      formatter: (cell) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'TECNOLOGIA',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">
          Tecnologia
        </div>
      ),
      sort: true,
      formatter: (cell) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">{parseTecnologia(cell)}</p>
        )
      }
    },
    {
      dataField: 'CONSUMO_MB',
      headerClasses: 'nao-selecionavel ',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">Consumo</div>
      ),
      sort: true,
      formatter: (cell) => {
        return <p className="fonte-cor-1 label-tabela-1">{`${cell}/MB`}</p>
      }
    },
    {
      dataField: 'DATA_SOLICITACAO',
      headerClasses: 'nao-selecionavel',
      text: (
        <div className="btn-header-nome-tabela-nova fonte-cor-1">
          Data da solicitação
        </div>
      ),
      sort: true,
      formatter: (cell) => {
        return (
          <span className="fonte-cor-1 label-tabela-1">
            {formatarData(cell, false)}
          </span>
        )
      }
    }
  ]

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (page) => {
      totalItens ? setPaginaAtual(page) : setPaginaAtual(1)
    }
  }

  const getValor = () => {
    const jsonConvertido =
      itens?.[0]?.JSON_DETAILS && JSON.parse(itens[0].JSON_DETAILS)

    return `R$ ${formatarMilhar(jsonConvertido?.totalValue ?? 0)}`
  }

  return (
    <S.DetalhamentoWrapper>
      {isCancelamento && (
        <S.InfosCliente>
          <Card titulo="Taxa e Multa de Cancelamento" descricao={getValor()} />
          <Card titulo="Solicitante" descricao={itens[0]?.STR_USUARIO} />
          <Card titulo="E-mail" descricao={itens[0]?.STR_EMAIL} />
          <Card
            titulo="Data e Hora"
            descricao={formatarData(itens[0]?.DATA_SOLICITACAO, false)}
          />
          <Card titulo="Motivo" descricao={itens[0]?.STR_MOTIVO} />
        </S.InfosCliente>
      )}
      <div className="container-tabela-preview">
        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                hover
                condensed
                data={itens}
                keyField="cd_fat_fat_preview"
                columns={colunas}
                classes="tabela-nova"
                bootstrap4
                bordered={false}
                noDataIndication="Nenhum item encontrado"
                {...paginationTableProps}
              />
              <PaginacaoTabela
                preview
                setPaginaAtual={setPaginaAtual}
                totalPaginas={totalPaginas}
                totalItens={totalItens}
                valorLimite={valorLimite}
                paginaAtual={paginaAtual}
                page={paginationProps.page}
                onPageChange={paginationProps.onPageChange}
              />
              <text className="itens-exibidos-tabela-solicitacoes-2 fonte-cor-1">
                Mostrando
                <span className="label-tabela-itens-exibidos">
                  {totalItens ? (paginaAtual - 1) * valorLimite + 1 : 0}
                </span>
                -
                <span className="label-tabela-itens-exibidos">
                  {Math.min(paginaAtual * valorLimite, totalItens)}
                </span>
                de
                <span className="label-tabela-itens-exibidos">
                  {totalItens}
                </span>
              </text>
            </div>
          )}
        </PaginationProvider>
      </div>
    </S.DetalhamentoWrapper>
  )
}
