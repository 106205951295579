import React, { useState, useEffect } from 'react';

import '../../css/pavFiltros/filtros.css'
import { Form, Button, Col } from "react-bootstrap";
import { setaDropdown } from '../../utils/alternanciaIcones';/*eslint-disable*/
import {api} from '../../conexoes/api';
import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem} from '../../redux/actions'
import SelectGenerico from '../componentesModais/selects/selectGenerico'
import decryptJWT from '../../utils/decryptJWT'
import { isPortal } from '../../utils/nivelCliente';
        
function Filtros(props) {
    const portalCliente = isPortal();

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setFiltroOperadora(filtros.OPERADORA)
            setFiltroTecnologia(filtros.TECNOLOGIA)
            setFiltroOperadoraPlano(filtros.OPERADORA_PLANO)
            setFiltroPlanoTipo(filtros.PLANO_TIPO)
            setFiltroPlanoMinimo(filtros.PLANO_MIN)
            setFiltroPlanoMaximo(filtros.PLANO_MAX)
            setFiltroGrupo(filtros.GRUPO)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }    

    function handleFuncaoEstadosRedux(){
        if(filtroOperadora !== '' && filtroOperadora){
            // despacho(mudarFiltros({CD_OPERADORA: 'test'}))
            arrayFiltrosSimcard.push("Operadora: "+filtroOperadora+"?OPERADORA")
        }
        if(filtroTecnologia !== '' && filtroTecnologia){
            arrayFiltrosSimcard.push("Tecnologia: "+filtroTecnologia+"?TECNOLOGIA")
        }
        if(filtroOperadoraPlano !== '' && filtroOperadoraPlano !== 'Planos' && filtroOperadoraPlano != "Selecione"){
            // despacho(mudarFiltros({OPERADORA_PLANO: 'test'}))
            
            const strPlano = isNaN(filtroOperadoraPlano) ? filtroOperadoraPlano : filtros.DS_OPERADORA_PLANO;
            arrayFiltrosSimcard.push("Operadora plano: "+strPlano+"?OPERADORA_PLANO")
        }
        if(filtroPlanoTipo !== '' && filtroPlanoTipo){
            arrayFiltrosSimcard.push("Plano tipo: "+filtroPlanoTipo+"?PLANO_TIPO")
        }
        if(filtroPlanoMinimo !== '' && filtroPlanoMaximo !== '' && filtroPlanoMinimo && filtroPlanoMaximo){
            arrayFiltrosSimcard.push("Plano: entre "+filtroPlanoMinimo+"MB e "+filtroPlanoMaximo+"MB"+"?PLANO_MIN")
        }else if(filtroPlanoMinimo !== '' && filtroPlanoMinimo){
            arrayFiltrosSimcard.push("Plano: a partir de "+filtroPlanoMinimo+"MB"+"?PLANO_MIN")
        }else if(filtroPlanoMaximo !== '' && filtroPlanoMaximo){
            arrayFiltrosSimcard.push("Plano: até "+filtroPlanoMaximo+"MB"+"?PLANO_MAX")
        }
        if(filtroGrupo !== '' && filtroGrupo){
            const strGrupo = isNaN(filtroGrupo) ? filtroGrupo : filtros.DS_GRUPO;
            arrayFiltrosSimcard.push("Grupo: " + strGrupo+"?GRUPO")
        }
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    const [dadoSelectGrupo, setDadoSelectGrupo] = useState([])
    const [arrOperadoraPlano, setArrOperadoraPlano] = useState([])
    const [arrOperadoraPlanoFiltrados, setArrOperadoraPlanoFiltrados] = useState([])
    
    useEffect(() => {
        async function carregarGrupos(){
            let todosGrupos = [];

            //carrega os 3 grupos padroes
            const gruposPadroes = await api.get('/m1/consultar/simcard_grupo?fl_padrao=1')
            todosGrupos = gruposPadroes.data.dados;
            
            // carrega o grupos do cliente
            const {data} = await api.get('/m1/consultar/simcard_grupo?fl_padrao=0&&cd_pav_cliente='+parseInt(decryptJWT('codigoCliente'))) 
            await data.dados.map((item,i)=>{
                todosGrupos.push(item);
            })
            setDadoSelectGrupo(todosGrupos)

        }
        carregarGrupos();
    }, []);

    //HOOKS DOS VALORES DOS FILTROS
    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [filtroTecnologia, setFiltroTecnologia] = useState('')
    const [filtroPlanoTipo, setFiltroPlanoTipo] = useState('')
    const [filtroPlanoMinimo, setFiltroPlanoMinimo] = useState('')
    const [filtroPlanoMaximo, setFiltroPlanoMaximo] = useState('')
    const [filtroGrupo, setFiltroGrupo] = useState('')
    const [filtroOperadoraPlano, setFiltroOperadoraPlano] = useState('Selecione')
    // HOOK do select
    const [dummy, setDummy] = useState('')
    const [revelarSelectOperadoraPlano, setRevelarSelectOperadoraPlano] = useState(false)

    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroOperadora=='') verificador.push(1)
            if(filtroOperadoraPlano=='') verificador.push(1)
            if(filtroTecnologia=='') verificador.push(1) 
            if(filtroPlanoTipo=='') verificador.push(1) 
            if(filtroPlanoMinimo=='') verificador.push(1) 
            if(filtroPlanoMaximo=='') verificador.push(1) 
            if(filtroGrupo=='') verificador.push(1) 
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 7) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroOperadora('')
            setFiltroOperadoraPlano('')
            setFiltroTecnologia('')
            setFiltroPlanoTipo('')
            setFiltroPlanoMinimo('')
            setFiltroPlanoMaximo('')
            setFiltroGrupo('')
            props.setFoiLimpado(false)
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroOperadora,filtroTecnologia,filtroOperadoraPlano,filtroPlanoTipo,filtroPlanoMinimo,filtroPlanoMaximo,filtroGrupo,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-plano-minimo"){
                setFiltroPlanoMinimo(valor)
            }
            if(id === "campo-texto-plano-maximo"){
                setFiltroPlanoMaximo(valor)
            }
        }
    }

    const operadoraPlanoObj = arrOperadoraPlanoFiltrados.find(item => item.descricao1 === filtroOperadoraPlano)
    const placeholderPlanoOperadora = operadoraPlanoObj?.descricao1 || filtros.DS_OPERADORA_PLANO || "Selecione";

    function handleChangeOperadora(op) {
        setFiltroOperadora(op)

        if(filtroOperadora && op) { // zerar filtro plano quando troca operadora
            setFiltroOperadoraPlano('Selecione')
        }
    }

    return (
        <Form ref={props.modal}
        onSubmit={(e, fa) => {
            let newItem = false;

            if(filtroOperadoraPlano && filtroOperadoraPlano != "Selecione") {
                if(operadoraPlanoObj?.cd_dsp_operadora_plano) {
                    newItem = { OPERADORA_PLANO: operadoraPlanoObj?.cd_dsp_operadora_plano, DS_OPERADORA_PLANO: operadoraPlanoObj?.descricao1 }
                } else if(filtroOperadoraPlano && filtros.OPERADORA_PLANO){
                    newItem = { OPERADORA_PLANO: filtros.OPERADORA_PLANO, DS_OPERADORA_PLANO: filtros.DS_OPERADORA_PLANO }

                }
            }

            if(isNaN(filtroGrupo) || filtros.DS_GRUPO) {
                if(!newItem) newItem = {}
                newItem.DS_GRUPO = isNaN(filtroGrupo) ? filtroGrupo : filtros.DS_GRUPO;
            }
            props.enviarFiltros(e, fa, newItem)
        }}
        className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-operadora","imagem-filtro-operadora")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">                    
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('Vivo')}>Vivo
                            <input defaultChecked={handlerCheck("OPERADORA","Vivo")} type="radio" id="OPERADORA_VIVO" name="OPERADORA" value="Vivo"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>                            
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('Claro')}>Claro
                            <input defaultChecked={handlerCheck("OPERADORA","Claro")} type="radio" id="OPERADORA_CLARO" name="OPERADORA" value="Claro"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>                            
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('Tim')}>Tim
                            <input defaultChecked={handlerCheck("OPERADORA","Tim")} type="radio" id="OPERADORA_TIM" name="OPERADORA" value="Tim"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>                            
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('Arqia')}>Arqia
                            <input defaultChecked={handlerCheck("OPERADORA","Arqia")} type="radio" id="OPERADORA_ARQUIA" name="OPERADORA" value="Arqia"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('Algar')}>Algar
                            <input defaultChecked={handlerCheck("OPERADORA","Algar")} type="radio" id="OPERADORA_ALGAR" name="OPERADORA" value="Algar"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('NEO (Rede Vivo)')}>NEO (Rede Vivo)
                            <input defaultChecked={handlerCheck("OPERADORA","NEO (Rede Vivo)")} type="radio" id="OPERADORA_NEO" name="OPERADORA" value="NEO (Rede Vivo)"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1" onChange={() => handleChangeOperadora('NEO (Rede Claro)')}>NEO (Rede Claro)
                            <input defaultChecked={handlerCheck("OPERADORA","NEO (Rede Claro)")} type="radio" id="OPERADORA_NEO" name="OPERADORA" value="NEO (Rede Claro)"/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                    </div>
                    {/* ------------------- */}
                    {filtroOperadora && !portalCliente &&
                    <>
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-id","imagem-filtro-id")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Plano</Form.Label>
                        <img id={"imagem-filtro-id"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-id"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Group className="select-generico-filtro">
                                {revelarSelectOperadoraPlano === false &&
                                    <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadoraPlano(!revelarSelectOperadoraPlano)}>
                                        <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{placeholderPlanoOperadora}</div>
                                        <img className={revelarSelectOperadoraPlano?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                    </Button>
                                }
                                {revelarSelectOperadoraPlano === true &&
                                    <SelectGenerico
                                        paramRota={"nomeplano_operadora"}
                                        paramCampo1={filtroOperadora}
                                        paramCampo2={null}
                                        paramCampo3={null}
                                        paramCampo4={null}
                                        setCodigo={setDummy}
                                        setDescricao1={setFiltroOperadoraPlano}
                                        setDescricao2={setDummy}
                                        setDescricao3={setDummy}
                                        setDescricao4={setDummy}
                                        setRevelarSelect={setRevelarSelectOperadoraPlano}
                                        placeholder={placeholderPlanoOperadora}
                                        sufixo={""}
                                        setDados={setArrOperadoraPlano} //ver porque não está setando
                                        dados={arrOperadoraPlano}
                                        setDadosFiltrados={setArrOperadoraPlanoFiltrados}
                                    >
                                    </SelectGenerico>
                                }
                            </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    </>
                    }
                    {/* ------------------- */}

                    {/* <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-tecnologia","imagem-filtro-tecnologia")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tecnologia </Form.Label>
                        <img id={"imagem-filtro-tecnologia"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-tecnologia"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1">2G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","2G")} type="radio" id="TECNOLOGIA_2G" name="TECNOLOGIA" value="2G" onChange={() => setFiltroTecnologia('2G')}/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1">3G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","3G")} type="radio" id="TECNOLOGIA_3G" name="TECNOLOGIA" value="3G" onChange={() => setFiltroTecnologia('3G')}/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1">4G
                            <input defaultChecked={handlerCheck("TECNOLOGIA","4G")} type="radio" id="TECNOLOGIA_4G" name="TECNOLOGIA" value="4G" onChange={() => setFiltroTecnologia('4G')}/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    {/* <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-plano-tipo","imagem-filtro-plano-tipo")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Plano tipo</Form.Label>
                        <img id={"imagem-filtro-plano-tipo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-plano-tipo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <label className="label-radio-filtros fonte-cor-1">Individual
                            <input defaultChecked={handlerCheck("PLANO_TIPO","Individual")} type="radio" id="PLANO_TIPO_INDIVIDUAL" name="PLANO_TIPO" value="Individual" onChange={() => setFiltroPlanoTipo('Individual')}/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <label className="label-radio-filtros fonte-cor-1">Compartilhado
                            <input defaultChecked={handlerCheck("PLANO_TIPO","Compartilhado")} type="radio" id="PLANO_TIPO_COMPARTILHADO" name="PLANO_TIPO" value="Compartilhado" onChange={() => setFiltroPlanoTipo('Compartilhado')}/>
                            <span className="checkbox-filtros campo-texto-cor-3"></span>
                        </label>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div> */}
                    {/* ------------------- */}
                    {/* <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-plano","imagem-filtro-plano")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Plano - MB </Form.Label>
                        <img id={"imagem-filtro-plano"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-plano"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <div className="row filtros-texto-linha-margin">
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Minimo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="PLANO_MIN"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroPlanoMinimo}
                                        onChange={e => validarNumero("campo-texto-plano-minimo", e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">MB</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className="label-texto-valor-filtros fonte-cor-1">Máximo</label>
                                <div className="d-flex">
                                    <Form.Control
                                        name="PLANO_MAX"
                                        className="filtros-texto-valor-mb campo-texto-cor-3"
                                        type="text"
                                        maxLength="4"
                                        value={filtroPlanoMaximo}
                                        onChange={e => validarNumero("campo-texto-plano-maximo", e.target.value)}
                                    />
                                    <label className="filtros-label-mb fonte-cor-1 campo-texto-cor-3">MB</label>
                                </div>
                            </div>
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>  */}
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="drop-filtros-menu" onClick={e => props.dropFiltros(e, "filtro-grupo","imagem-filtro-grupo")}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Grupo </Form.Label>
                        <img id={"imagem-filtro-grupo"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div id={"filtro-grupo"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        {dadoSelectGrupo.map((grupo) => (
                            <>
                                <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                                <label className="label-radio-filtros fonte-cor-1" onChange={()=> setFiltroGrupo(grupo.ds_nome)}>{grupo.ds_nome}
                                    <input defaultChecked={handlerCheck("GRUPO",grupo.cd_dsp_simcard_grupo)} type="radio" name="GRUPO" value={grupo.cd_dsp_simcard_grupo} onClick={e => setFiltroGrupo(e.target.value)}/>
                                    <span className="checkbox-filtros campo-texto-cor-3"></span>
                                </label>
                            </>
                        ))}
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default Filtros;
