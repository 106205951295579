//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { useForm } from 'react-hook-form'
import { api } from '../../../conexoes/api'
import '../../../css/painelDispositivos/dspCancelamentoReposicao/cancelamento.css'
//ICONES
import {
    alertaAtencao,
    elipseVerde,
    exclamacao,
    fecharJanela,
    setaDropdown
} from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector } from 'react-redux'
import { revelarCancelamento } from '../../../redux/actions/index'

import { formatarMilhar } from '../../../utils/formatarMilhar'
import { identificarValidacaoAcoes } from '../../../utils/identificarValidacaoAcoes'
import TabelaGenerica from '../../componentesModais/tabela/tabela'
//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'

// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

import RetornarParametro from '../../../utils/decryptJWT'

// COLETA USABILIDADE
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import SelectMotivo from '../../componentesModais/selects/selectMotivo'

//VARIÁVEIS DE SESSÃO
let validacaoLinhas = false
let offset = 0
let valorTotal = 0.0
let valorTotalMulta = 0.0
let valorTaxaCancelamento = 0.0
let validacaoConcluida = false
let linhasValidas = false
let linhasInvalidas = false
let linhasRemovidas = []
let refazerCalculoValorTotal = true

export default function CancelamentoReposicao(props) {
  // SPINNER
  const [spinAtivo, setSpinAtivo] = useState(true)

  const { register, handleSubmit, errors } = useForm()
  const [dsMotivo, setDsMotivo] = useState('')
  const [itens, setItens] = useState([])
  //HOOKS MODAIS MENSAGENS
  const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] =
    useState(false)
  const [revelarMensagemLinhasInvalidas, setRevelarMensagemLinhasInvalidas] =
    useState(true)
  const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
  const [mensagemErro, setMensagemErro] = useState(
    'Ocorreu um erro ao executar esta ação'
  )
  const [ADE_CODCAT, setADE_CODCAT] = useState()
  const [ADE_CODCAU, setADE_CODCAU] = useState()
  const [dsTelefone, setDsTelefone] = useState('')
  const [erroTelefone, setErroTelefone] = useState(true)
  //OUTROS HOOKS
  const despacho = useDispatch()
  const modal = useRef()
  const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
  let filtros = useSelector((state) => state.filtrosState.filtros)
  let buscaArquivo = useSelector(
    (state) => state.buscaArquivoState.buscaArquivo
  )
  let modoSelecaoSimcard = useSelector(
    (state) => state.manejadorState.modoSelecaoSimcard
  )
  let conteudoBusca = useSelector((state) => state.manejadorState.conteudoBusca)
  let separadorLinha = useSelector(
    (state) => state.manejadorState.separadorLinha
  )
  //HOOK DE SESSAO
  const [revelarSelectMotivo, setRevelarSelectMotivo] = useState(false)
  const [inputRevelado, setInputRevelado] = useState('Selecione')
  const [renderizar, setRenderizar] = useState(false)
  const [numeroLinhasValidas, setNumeroLinhasValidas] = useState(-1)
  const [revelarDetalhamentoValores, setRevelarDetalhamentoValores] =
    useState(false)
  //HOOKS DE VALORES E TRATATIVAS DE VALORES
  const [stringValorTotalMulta, setStringValorTotalMulta] = useState('')
  const [stringTaxaCancelamento, setStringTaxaCancelamento] = useState('')
  const [stringValorTotal, setStringValorTotal] = useState('')
  //VALORES CONSTANTES
  const taxaDeCancelamento = 10.0
  //VARIAVEIS DE PAGINACAO
  const valorLimite = 50
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [ordenador, setOrdenador] = useState('')
  const [fineValue, setFineValue] = useState('')
  const [taxaValue, setTaxaValue] = useState('')
  const [totalValue, setTotalValue] = useState('')

  const iconeAlerta = (
    <img className="icone-alerta" src={exclamacao()} alt="exclamacao" />
  )

  const regexTelefone =
    /^(?:\+?\d{1,3}\s?)?(\(?\d{2}\)?\s?)?\d{4,5}[-\s]?\d{4}$/
  const validarTelefone = (telefone) => {
    if (!telefone) return 'Informe o telefone para contato.'
    if (!regexTelefone.test(telefone)) return 'Telefone inválido.'
    return ''
  }

  const handleTelefone = (e) => {
    setDsTelefone(e)
    const erro = validarTelefone(e)
    setErroTelefone(erro)
  }

  /**Taxa de Cancelamento: é definido linhas pela constante
   * taxaDeCancelamento
   *
   * Multa de Cancelamento: é cobrado 50% do valor do plano mensal,
   * calculado utilizando a seguinte formula
   * (ValorPlano / 60 )*(dias restantes do contrato)
   * a divisao por 60 vem de o valor ser mensal, logo o valor diario é
   * adquirido dividindo o valor do plano por 30 e, como é cobrado 50%
   * deste valor, 50% = 1/2, X /30 /2 = X / 60
   */

  //HANDLE FUNCTIONS

  function handleCancelar() {
    ColetaClickstream(1086) // 'cancelamentoCancelar'
    validacaoLinhas = false
    offset = 0
    valorTotal = 0.0
    valorTotalMulta = 0.0
    valorTaxaCancelamento = 0.0
    validacaoConcluida = false
    linhasValidas = false
    linhasInvalidas = false
    linhasRemovidas = []
    refazerCalculoValorTotal = true
    setRevelarMensagemLinhasInvalidas(false)
    setSpinAtivo(false)
    despacho(revelarCancelamento(false))
  }

  function handleClickFora(e) {
    if (!modal.current.contains(e.target)) {
      handleCancelar()
    }
  }

  function handleMotivo(motivo) {
    setDsMotivo(motivo)
    // setCaracteresMensagem(1000 - motivo.length)
  }

  async function handleRemoverLinha(CD_SIMCARD) {
    ColetaClickstream(1085) // 'cancelamentoRemoverLinha'
    let position = false
    linhasValidas.map((item, i) => {
      if (item.CD_SIMCARD === CD_SIMCARD) {
        position = i
      }
    })
    if (!isNaN(position)) {
      linhasRemovidas.push(linhasValidas[position])
      linhasValidas.splice(position, 1)
      setNumeroLinhasValidas(linhasValidas.length)
      refazerCalculoValorTotal = true
    }
    if (linhasValidas.length < 1) {
      handleCancelar()
    }
    if (linhasValidas.length % 50 === 0) {
      setPaginaAtual(paginaAtual - 1)
    }
  }

  async function handleCalcularValorTotal() {
    setSpinAtivo(true)
    /**Calculo de multa chama a funcao calcular_multa do arquivo
     * back/src/controles/dispositivos/dspSimcard.js
     * do back-end e este envia uma query que rotorna o valor total da multa,
     * este valor é entao salvo na variavel valorTotalMulta
     */
    let linhasValidasAux = []
    await Promise.all(
      linhasValidas.map((item, i) => {
        linhasValidasAux.push(item.CD_SIMCARD)
      })
    )
    let resposta = await api
      .post('/m1/consultar/simcard/calcular_multa', {
        codigoCliente: codigoCliente,
        linhasValidas: linhasValidasAux
      })
      .then(function (resposta) {
        valorTotalMulta = resposta.data.dados[0].VALOR_MULTA
        valorTaxaCancelamento = linhasValidas.length * taxaDeCancelamento
        valorTotal = valorTotalMulta + valorTaxaCancelamento
        setFineValue(valorTotalMulta)
        setTaxaValue(valorTaxaCancelamento)
        setTotalValue(valorTotal)

        produzirStringsValores()
        setSpinAtivo(false)
      })
      .catch(function (erro) {
        setSpinAtivo(false)
        setMensagemErro('Erro ao calcular valores, favor tentar novamente!')
        setRevelarMensagemErro(true)
      })
  }

  function produzirStringsValores() {
    /**Converte os valores calculados para multa, taxa de cancelamento e
     * valor total para strings que serao exibidos na tela
     */
    if (valorTotalMulta != 0) {
      let multaString = formatarMilhar(valorTotalMulta)
      setStringValorTotalMulta(multaString)
    }
    if (valorTaxaCancelamento != 0) {
      let taxaDeCancelamentoString = formatarMilhar(valorTaxaCancelamento)
      setStringTaxaCancelamento(taxaDeCancelamentoString)
    }
    if (valorTotal != 0) {
      let valorTotalString = formatarMilhar(valorTotal)
      setStringValorTotal(valorTotalString)
    }
  }

  async function handleSalvar() {
    ColetaClickstream(1087) // 'cancelamentoConfirmar'
    setSpinAtivo(true)
    let temporario = new Date()
    let dataHora =
      temporario.toISOString().substring(0, 10) +
      ' ' +
      temporario.getHours() +
      ':' +
      temporario.getMinutes() +
      ':' +
      temporario.getSeconds()

    let linhasValidasAuxCDSIMCARD = []
    linhasValidas.map((item, i) => {
      linhasValidasAuxCDSIMCARD.push(item.CD_SIMCARD)
    })
    let linhasValidasAuxN0_MSISDN = []
    linhasValidas.map((item, i) => {
      linhasValidasAuxN0_MSISDN.push(item.CALLERID)
    })

    setSpinAtivo(true)
    await api
      .post('/m1/cadastrar/solicitacao', {
        fl_operadora: 0,
        simcards: linhasValidasAuxCDSIMCARD,
        FULLCALLERID: linhasValidasAuxN0_MSISDN,
        cd_crm_solicitacao_tipo: 2,
        cd_crm_solicitacao_status: 1,
        ds_crm_solicitacao: dsMotivo,
        dt_inicio: dataHora,
        dt_fim: undefined,
        dt_previsao: undefined,
        ds_rota_permissao: 'veye/dispositivos/acoes/cancelamento',
        ADE_CODCAT: ADE_CODCAT,
        ADE_CODCAU: ADE_CODCAU,
        dsTelefone: dsTelefone,
        fineValue: fineValue,
        taxaValue: taxaValue,
        totalValue: totalValue
      })
      .then(function (response) {
        setSpinAtivo(false)
        handleMensagemSucesso(false)
      })
      .catch(function (error) {
        setSpinAtivo(false)
        setMensagemErro('Erro na solicitação')
        handleMensagemErro(false)
      })
  }

  //USE EFFECTS
  useEffect(() => {
    //ADICIONA A MODAL O EVENT LISTENER QUE VERIFICA CLICKS FORA DA MODAL E FECHA A MODAL
    document.addEventListener('mousedown', handleClickFora)
    return () => document.removeEventListener('mousedown', handleClickFora)
  }, [])

  useEffect(() => {
    setSpinAtivo(true)
    offset = (paginaAtual - 1) * valorLimite
    async function carregarLinhas() {
      if (validacaoConcluida === false) {
        /**DURANTE A PRIMEIRA RENDERIZACAO DA MODAL, EXECUTA A VALIDACAO DAS LINHAS
         * REMOVENDO DA SOLICITACAO LINHAS QUE APRESENTEM BLOQUEIO DEVIDO A OUTRAS
         * SOLICITACOES
         */
        validacaoLinhas = await identificarValidacaoAcoes(
          props.itensSelecionados,
          2,
          { filtros, buscaArquivo },
          modoSelecaoSimcard,
          conteudoBusca,
          separadorLinha
        )
        /**dsp_verificador_simcard_acao_todos_p
         */
        linhasValidas = validacaoLinhas.itensValidos
        linhasInvalidas = validacaoLinhas.itensInvalidos
        setNumeroLinhasValidas(linhasValidas.length)
        validacaoConcluida = true
      }

      if (validacaoLinhas.itensValidos.length > 0) {
        if (refazerCalculoValorTotal === true) {
          handleCalcularValorTotal()
          refazerCalculoValorTotal = false
        }

        if (
          revelarMensagemLinhasInvalidas === true &&
          linhasInvalidas.length > 0 &&
          mensagemErro != 'Erro ao calcular valores, favor tentar novamente!'
        ) {
          handleRevelarMensagemLinhasInvalidas()
          setRevelarMensagemLinhasInvalidas(false)
        }

        let linhasValidasAux = []
        linhasValidas.map((item, i) => {
          linhasValidasAux.push(item.CD_SIMCARD)
        })
        let resposta = await api.post(
          '/m1/consultar/simcard/linhas_cancelamento',
          {
            linhasValidas: linhasValidasAux,
            offset: offset,
            ordenador: ordenador
          }
        )
        setItens(resposta.data.dados)

        setSpinAtivo(false)
      } else {
        if (linhasInvalidas.length > 0) {
          handleRevelarMensagemLinhasInvalidas()
          setRevelarMensagemLinhasInvalidas(false)
        } else {
          setMensagemErro('Todas as linhas selecionadas estão invalidas.')
          handleMensagemErro(false)
        }
      }
    }
    carregarLinhas()
  }, [paginaAtual, linhasValidas.length, renderizar, ordenador])

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true)
    } else {
      setRevelarMensagemSucesso(false)
      handleCancelar()
    }
    if (confirmacao) {
      props.setRenderizar(!props.renderizar)
    }
  }

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true)
    } else {
      setRevelarMensagemErro(false)
      handleCancelar()
    }
  }

  function handleRevelarMensagemLinhasInvalidas() {
    setRevelarMensagemAtencaoInvalido(true)
  }

  function handleFecharMensagemItensInvalidos() {
    if (linhasValidas.length < 1) {
      handleCancelar()
    }
    setRevelarMensagemAtencaoInvalido(false)
  }

  const colunasItensInvalidos = [
    {
      dataField: 'CALLERID',
      text: 'Linha',
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-nao-permitidos">
            {cell != undefined ? cell : '-'}
          </p>
        )
      }
    },
    {
      dataField: 'ds_motivo',
      text: 'Motivo',
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-nao-permitidos">
            {cell != undefined ? cell : '-'}
          </p>
        )
      }
    }
  ]

  //FRONT-END VISUAL CODE
  return (
    <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
      <div className="modal-novo-cancelamento fundo-cor-6">
        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinerVeye />
            </div>
          </div>
        )}
        <Form.Row className="margin-top-cancelamento">
          <Col>
            <img
              className="icone-label-janela nao-selecionavel"
              src={elipseVerde()}
              alt="elipse"
            />
            <Form.Label>
              <div className="fonte-cor-1 label-janela">Cancelamento</div>
            </Form.Label>
            <Button
              className="botao-fechar-janela"
              variant="danger"
              onClick={() => handleCancelar()}
            >
              <img
                className="botao-fechar-janela-icone"
                src={fecharJanela()}
                alt="fechar-janela"
              />
            </Button>
          </Col>
        </Form.Row>
        {itens != '' && itens != undefined && itens != null && (
          <TabelaGenerica
            tabela={'modalCancelamentoDispositivos'}
            selecaoLinha={false}
            selecaoParque={false}
            selecaoPagina={false}
            expancaoLinha={false}
            colunaAcao={'remover'}
            ordenador={ordenador}
            setOrdenador={setOrdenador}
            itens={itens}
            paginaAtual={paginaAtual}
            setPaginaAtual={setPaginaAtual}
            limitePagina={valorLimite}
            totalItens={numeroLinhasValidas}
            setItemRemovido={handleRemoverLinha}
          />
        )}
        <Form.Row>
          <Col>
            <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">
              {numeroLinhasValidas} linhas selecionadas
            </span>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="container-botao-revelar-detalhamento-valores">
              <ul className="campo-select-lista-cancelamento-1">
                <li>
                  <div
                    className="campo-select-generico-opcao-cancelamento-1"
                    onClick={() =>
                      setRevelarDetalhamentoValores(!revelarDetalhamentoValores)
                    }
                  >
                    <div className="label-cancelamento fonte-cor-1 negrito">
                      Valor total
                    </div>
                    <div className="alinhamento">
                      <div className="label-valor-cancelamento-1 fonte-cor-3 negrito">
                        {' '}
                        R$ {stringValorTotal != '' ? stringValorTotal : ' 0,00'}
                      </div>
                      <img
                        className={
                          revelarDetalhamentoValores === true
                            ? 'dropdown-cancelamento campo-select-icone-ativado nao-selecionavel'
                            : 'dropdown-cancelamento campo-select-icone-desativado nao-selecionavel'
                        }
                        src={setaDropdown()}
                        alt="drop"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {revelarDetalhamentoValores && (
              <>
                <div className="campo-select-triangulo-cor-1 campo-select-triangulo select-triangulo-cancelamento" />
                <div className="container-detalhamento-valores-dropdown">
                  <ul className="campo-select-lista-cancelamento">
                    <li>
                      <div className="campo-select-generico-opcao-cancelamento ">
                        <div className="label-cancelamento fonte-cor-1 negrito">
                          Multa de contrato
                        </div>
                        <div className="label-valor-cancelamento fonte-cor-3 negrito">
                          {' '}
                          R${' '}
                          {stringValorTotalMulta != ''
                            ? stringValorTotalMulta
                            : ' 0,00'}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="campo-select-generico-opcao-cancelamento ">
                        <div className="label-cancelamento fonte-cor-1 negrito">
                          Taxa de cancelamento
                        </div>
                        <div className="label-valor-cancelamento fonte-cor-3 negrito">
                          {' '}
                          R${' '}
                          {stringTaxaCancelamento != ''
                            ? stringTaxaCancelamento
                            : ' 0,00'}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="campo-select-generico-opcao-cancelamento">
                        <div className="label-cancelamento fonte-cor-1 negrito">
                          Valor total
                        </div>
                        <div className="label-valor-cancelamento fonte-cor-3 negrito">
                          {' '}
                          R${' '}
                          {stringValorTotal != '' ? stringValorTotal : ' 0,00'}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </Col>
        </Form.Row>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>
                <div className="fonte-cor-1 label-campo label-campo-margin">
                  Motivo
                </div>
              </Form.Label>
              {errors.motivo_cancelamento_reposicao && (
                <p className="fonte-cor-3 label-alerta">
                  {iconeAlerta} Informe o motivo.
                </p>
              )}
              {revelarSelectMotivo === false && (
                <Button
                  className="campo-select-cancelamento campo-texto-cor-3 campo-select"
                  onClick={() => setRevelarSelectMotivo(!revelarSelectMotivo)}
                  ref={register({ required: true })}
                  onChange={(e) => handleMotivo(e.target.value)}
                >
                  <div className="fonte-cor-1 label-campo">{inputRevelado}</div>

                  <img
                    className={
                      revelarSelectMotivo
                        ? 'campo-select-icone-ativado nao-selecionavel'
                        : 'campo-select-icone-desativado nao-selecionavel'
                    }
                    src={setaDropdown()}
                    alt="drop"
                  />
                </Button>
              )}
              {revelarSelectMotivo && (
                <SelectMotivo
                  setRevelarSelectMotivo={setRevelarSelectMotivo} //Hook para fechar a modal
                  setInputRevelado={setInputRevelado} //Nome do FormaEnvio
                  setADE_CODCAT={setADE_CODCAT}
                  setADE_CODCAU={setADE_CODCAU}
                  setDsMotivo={setDsMotivo}
                  inputRevelado={inputRevelado}
                ></SelectMotivo>
              )}
              {inputRevelado == 'Outros' && (
                <>
                  <div class="campo-select-triangulo-margin campo-select-triangulo-cor-1 campo-select-triangulo"></div>
                  <Form.Control
                    className="campo-area-margin campo-texto-cor-3 campo-texto-area"
                    as="textarea"
                    rows="3"
                    name="motivo_cancelamento_reposicao"
                    ref={register({ required: true })}
                    maxlength="1000"
                    placeholder="Descreva aqui o motivo"
                    onChange={(e) => handleMotivo(e.target.value)}
                  ></Form.Control>
                </>
              )}
            </Col>
            <Col>
              <Form.Label>
                <div className="fonte-cor-1 label-campo label-campo-margin">
                  Telefone para Contato
                </div>
              </Form.Label>
              <Form.Control
                name="telefone_contato"
                type="text"
                className="campo-texto-cor-3 input-cancelamento-telefone"
                value={dsTelefone}
                onChange={(e) => handleTelefone(e.target.value)}
                placeholder="Digite o telefone"
              />
            </Col>
          </Row>
        </Form.Group>
        {erroTelefone && (
          <p className="fonte-cor-3 label-alerta">
            {iconeAlerta} Informe o telefone para contato.
          </p>
        )}

        <Form.Row className="label-termos-cancelamento-2">
          <Col>
            <span className="atencao-label-cancelamento">
              <span className="fonte-cor-3 label-normal negrito">Atenção:</span>

              <span className="fonte-cor-1 label-normal-cancelamento">
                Caso prefira ter as isenções da taxa de cancelamento, temos a
                opção de Reposição, basta ir em ações novamente e selecionar “
                <strong>reposição</strong>”. Este tipo de solicitação só é
                atendida dentro do horário comercial (08h - 17:30h).
              </span>
            </span>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="botoes-cancelamento">
              {inputRevelado != 'Selecione' && !erroTelefone ? (
                <Button
                  type="submit"
                  className="botao-confirmar"
                  variant="success"
                  size="sm"
                >
                  <div className="fonte-cor-4">Confirmar</div>
                </Button>
              ) : (
                <Button className="botao-confirmar-desabilitado" size="sm">
                  <div className="fonte-cor-4">Confirmar</div>
                </Button>
              )}

              <Button
                className="botao-cancelar"
                variant="success"
                size="sm"
                onClick={() => handleCancelar()}
              >
                <div className="fonte-cor-2">Cancelar</div>
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>

      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={'Sucesso'}
              mensagemParagrafo1={'Sua solicitação foi enviada.'}
              mensagemBotao={'Continuar'}
            />
          </div>
        </div>
      )}
      {revelarMensagemAtencao && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemAtencao
              handleMensagemAtencao={handleMensagemAtencao}
              mensagemTitulo={'Remover linha?'}
              mensagemBotao={'Remover'}
            />
          </div>
        </div>
      )}
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={'Erro'}
              mensagemParagrafo1={mensagemErro}
              mensagemBotao={'Retornar'}
            />
          </div>
        </div>
      )}
      {revelarMensagemAtencaoInvalido && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
              <div className="mensagem-container-icone">
                <img
                  className="mensagem-icone"
                  src={alertaAtencao()}
                  alt="sucesso"
                />
              </div>
              <div className="fonte-cor-1 label-janela">
                {linhasInvalidas.length} linhas foram retiradas
              </div>
              <div className="container-tabela-modal-itens-nao-permitidos">
                <BootstrapTable
                  classes="tabela-transparente-modais"
                  keyField="ds_linha"
                  condensed={true}
                  data={linhasInvalidas}
                  columns={colunasItensInvalidos}
                  noDataIndication={!spinAtivo && 'Nenhum item encontrado'}
                  bootstrap4={true}
                />
              </div>
              <div className="mensagem-container-botao">
                <Button
                  className="mensagem-botao-cancelar"
                  variant="success"
                  size="sm"
                  onClick={(e) => handleFecharMensagemItensInvalidos()}
                >
                  <div className="fonte-cor-2">Cancelar</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  )
}
