import styled from 'styled-components'

export const InfosCliente = styled.div`
  height: 124px;
  background-color: ${({ theme }) => theme.cor68};
  border-radius: 16px;
  gap: 15px;
  padding: 20px;
  display: flex;
  grid-template-columns: repeat(1fr, 5);
  max-width: 99%;
  justify-content: center;
`

export const Card = styled.div`
  width: 100%;
  font-family: 'Roboto';
  display: grid;
  height: 84px;
  padding: 8px;
  gap: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.cor69};
`

export const DetalhamentoWrapper = styled.div`
  display: grid;
  gap: 16px;
`

export const Titulo = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.cor9};
`

export const Descricao = styled.h1`
  font-size: 16px;
  color: ${({ theme }) => theme.cor9};
`
